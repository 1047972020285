import React, { useRef } from 'react';
import WelcomeHeader from '../WelcomeHeader';
import { Row, Col, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../../components/Footer';

const CaseStudyPage = () => {
    const targetAboutRef = useRef(null);

    const handleScroll = () => {
        targetAboutRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const history = useHistory();
    const themeType = useSelector(({ settings }) => settings.themeType);
    const challengesData = [
        {
            id: 1,
            title: "Manual Payment Tracking",
            description: "Hundreds of daily payments were recorded offline through receipts, requiring hours of end-of-day verification and record-keeping"
        },
        {
            id: 2,
            title: "Privacy & Privilege Control Concerns",
            description: "Ensuring student information privacy while allowing staff to only access strictly necessary information to do their job was extemely challenging"
        },
        {
            id: 3,
            title: "Complex Profit Sharing Calculations",
            description: "Monthly calculations for teacher profit-sharing, with variable percentages across different payments, became a tedious process"
        },
        {
            id: 4,
            title: "Paper-Based Student Records",
            description: "Retrieving student data from stacks of paper records was time-consuming and inefficient and almost impossible in some instances"
        },
        {
            id: 5,
            title: "Lack of Centralized Digital Classroom Solutions",
            description: "Communication, classes, and resources were scattered across multiple platforms, causing fragmentation and inefficiency"
        },
        {
            id: 6,
            title: "Ineffective Attendance Solutions",
            description: "Previous auto-attendance devices underperformed, forcing Edbase to rely on manual attendance"
        }
    ];

    const impactsData = [
        {
            id: 1,
            title: "Saved 1.5h/stakeholder per day"
        },
        {
            id: 2,
            title: "Reduced Error Rate"
        },
        {
            id: 3,
            title: "Eliminated Previously Under-reported Financial Loss"
        },
        {
            id: 4,
            title: "Solved Privacy Concerns"
        },
        {
            id: 5,
            title: "Unified All Services"
        },
        {
            id: 6,
            title: "Helped The Management Make Crucial  Decision with Insights"
        },
    ];

    return (
        <div className="gx-welcome-pagev2">
            <WelcomeHeader />
            <main>
                {/* Banner Section*/}
                <section className="gx-case-study-banner-section gx-section-padding">
                    <div className='gx-landing-container'>
                        <div className="gx-case-study-banner-content">
                            <img loading="lazy" className="gx-case-study-hero-image" src="/assets/images/welcomev2/case-study/hero.png" alt="hero_image" />
                            <h1 className="gx-mt-5">Modernizing & Scaling The Operation of Edbase</h1>
                            <p className=".gx-case-study-banner_span gx-mt-4">From complex challenges to seamless solutions, discover how Edutechs helped Edbase transform their entire educational ecosystem with a tailored approach.
                            </p>
                            <button onClick={handleScroll} className="gx-btn-primary gx-case-study-btn gx-mb-0 gx-px-5 gx-rounded-xxl gx-mt-5 gx-mb-5 ">Explore Our Impact</button>
                        </div>
                    </div>

                </section>

                {/* About Section */}
                <section ref={targetAboutRef} className="gx-case-study-about-section gx-py-5 gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-case-study-about-content">
                            <h1>About Edbase</h1>
                            <div className="gx-section-padding">
                                <div className="gx-landing-container">
                                    <div className="gx-case-study-wrapper">
                                        <div className="gx-case-study-left">
                                            <img loading="lazy"
                                                src="/assets/images/welcomev2/case-study/left.png"
                                                alt="Ebase Logo"
                                                className="gx-left-image gx-case-study-image-left"
                                            />
                                        </div>
                                        <div className="gx-case-study-right">
                                            <div className="gx-case-study-image-wrapper">
                                                <img loading="lazy"
                                                    src="/assets/images/welcomev2/case-study/NewsCard1.png"
                                                    alt="Top Right 2"
                                                    className="gx-case-study-image-middle1"
                                                />
                                            </div>
                                            <div className="gx-case-study-image-wrapper">
                                                <img loading="lazy"
                                                    src="/assets/images/welcomev2/case-study/NewsCard2.png"
                                                    alt="Top Right 2"
                                                    className="gx-case-study-image-right1 gx-case-study-image-middle2"
                                                />
                                            </div>
                                            <div className="gx-case-study-image-wrapper">
                                                <img loading="lazy"
                                                    src="/assets/images/welcomev2/case-study/NewsCard3.png"
                                                    alt="Top Right 2"
                                                    className="gx-case-study-image-middle1"
                                                />
                                            </div>
                                            <div className="gx-case-study-image-wrapper">
                                                <img loading="lazy"
                                                    src="/assets/images/welcomev2/case-study/NewsCard4.png"
                                                    alt="Top Right 2"
                                                    className="gx-case-study-image-right2 gx-case-study-image-end"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="gx-case-study-about-heading_span gx-mx-5">Edbase is a large, after-school educational institute with over 1,700 active students, 20+ teachers, and multiple stakeholders. With traditional paper-based systems and fragmented processes, Edbase was struggling to manage daily operations efficiently and securely. That's where Edutechs stepped in.</p>
                        </div>
                    </div>

                </section>

                {/* Challenges Section */}
                <section className="gx-case-study-challenge-section gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-case-study-challenges-content">
                            <h1 className="gx-case-study-challenges-title">Challenges that Held Edbase Back</h1>
                            <p className="gx-case-study-challenges_span gx-mt-1">Edbase faced significant operational obstacles that hindered their growth and efficiency. Here’s a look at some of the key issues they were up against -</p>
                            <Row className="gx-case-study-challenges-row gx-mt-5">
                                {
                                    challengesData?.map((challenges, i) =>
                                        <Col key={challenges.id} xs={24} sm={24} md={12} lg={12} xl={8}>
                                            <Card className="gx-case-study-challenges-card">
                                                <h1>{i + 1}</h1>
                                                <h3 className="gx-my-3">{challenges.title}</h3>
                                                <p className="gx-case-study-challenges-card-info">{challenges.description}</p>
                                            </Card>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    </div>

                </section>

                {/* Solutions */}
                <section className="gx-case-study-solution-section gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-case-study-solutions-content">
                            <h1>Solutions We Offered to Edbase</h1>
                            <p className="gx-case-study-solutions_span gx-mt-1">Edutechs provided a comprehensive solution tailored to each of Edbase’s unique challenges, equipping them with powerful tools and efficient workflows to streamline operations and create a seamless educational experience. Here’s how we addressed each of their pain points -</p>
                            <Row className="gx-case-study-solutions-row gx-mt-4">
                                <Col className="gx-case-study-solutions-col" xs={24} sm={24} md={10} lg={10} xl={10}>
                                    <Card className="gx-case-study-solutions-card gx-case-study-solutions-card-payment">
                                        <img loading="lazy" className="gx-case-study-solutions-icon" src="/assets/images/welcomev2/case-study/credit-card-icon.png" alt="creditCardIcon" />
                                        <h1 className="gx-my-3">Seamless Payment Management</h1>
                                        <p className="gx-case-study-solutions_span">Our system verifies each transaction instantly, eliminating the need for end-of-day manual checks. Payments are neatly organized and accessible, with stakeholders able to view summaries by class, student, or teacher, ensuring transparency and ease of access.</p>

                                        <div className="gx-case-study-solutions-card-image-right">
                                            <img loading="lazy" className="gx-case-study-solutions-right-image gx-case-study-solutions-edupay-image" src="/assets/images/welcomev2/case-study/edupay.png" alt="eduPayImage" />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                    <Card className="gx-case-study-solutions-card gx-case-study-solutions-card-payment">
                                        <img loading="lazy" className="gx-case-study-solutions-icon" src="/assets/images/welcomev2/case-study/team-icon.png" alt="teamIcon" />
                                        <h1 className="gx-my-3">Enhanced Privacy and Access Control</h1>
                                        <p className="gx-case-study-solutions_span">With our privilege control, Edbase can fine-tune what each teacher and moderator can see and do, from student contact info to classroom access. Additionally, privileges can be set on per-classroom basis, ensuring moderators only see relevant information for specific classes, such as assessments for certain subjects.</p>

                                        <div className="gx-case-study-solutions-card-image-right">
                                            <img loading="lazy" className="gx-case-study-solutions-right-image gx-case-study-privilege-image" src="/assets/images/welcomev2/case-study/privilege.png" alt="privilegeImage" />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="gx-case-study-solutions-row gx-mt-4">
                                <Col className="gx-case-study-solutions-col" xs={24} sm={24} md={12} lg={14} xl={14}>
                                    <Card className="gx-case-study-solutions-card gx-case-study-solutions-card-payment">
                                        <img loading="lazy" className="gx-case-study-solutions-icon" src="/assets/images/welcomev2/case-study/automated-icon.png" alt="automatedIcon" />
                                        <h1 className="gx-my-3">Automated Profit Splitting</h1>
                                        <p className="gx-case-study-solutions_span">We built a custom "Profit Splitting" add-on for Edbase, simplifying their profit-sharing model. With pre-set percentages, the system automatically calculates how much of each payment goes to teachers and the institution, adjustable by teacher, class, or payment type. This feature saves Edbase’s management team up to two hours daily, streamlining payouts and improving accuracy.</p>

                                        <div className="gx-case-study-solutions-card-image-left">
                                            <img loading="lazy" className="gx-case-study-solutions-left-image gx-case-study-solutions-add-ons-image" src="/assets/images/welcomev2/case-study/add-ons.png " alt="installedImage" />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                                    <Card className="gx-case-study-solutions-card gx-case-study-solutions-card-payment">
                                        <img loading="lazy" className="gx-case-study-solutions-icon" src="/assets/images/welcomev2/case-study/cv-icon.png" alt="cvIcon" />
                                        <h1 className="gx-my-3">Instant Student Lookup</h1>
                                        <p className="gx-case-study-solutions_span">With Edutechs’ cloud-based student management, Edbase can quickly find any student using name, ID, or phone number. Student records are securely stored, accessible from any device, and supported by robust analytics tools—providing insights on payments, attendance, assessments, and more.</p>

                                        <div className="gx-case-study-solutions-card-image-left">
                                            <img loading="lazy" className="gx-case-study-solutions-left-image gx-case-study-solutions-students-page-image" src="/assets/images/welcomev2/case-study/students-page.png" alt="studentsInterfaceImage" />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>


                            <Row className="gx-case-study-solutions-row gx-mt-4">
                                <Col className="gx-case-study-solutions-col" xs={24} sm={24} md={12} lg={10} xl={10}>
                                    <Card className="gx-case-study-solutions-card gx-case-study-solutions-card-payment">
                                        <img loading="lazy" className="gx-case-study-solutions-icon" src="/assets/images/welcomev2/case-study/crm-icon.png" alt="crmIcon" />
                                        <h1 className="gx-my-3">All-in-One LMS Platform</h1>
                                        <p className="gx-case-study-solutions_span">Edutechs LMS offers a suite of essential tools, covering every need for educational institutions. Edbase now enjoys an integrated platform for SMS communications, classroom and student management, online and offline payments, online admissions, cloud storage for videos and materials, document generation, and more—all in one place.</p>

                                        <div className="gx-case-study-solutions-card-image-right">
                                            <img loading="lazy" className="gx-case-study-solutions-right-image gx-case-study-solutions-installed-image" src="/assets/images/welcomev2/case-study/installed.png" alt="installedImage" />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                                    <Card className="gx-case-study-solutions-card gx-case-study-solutions-card-payment">
                                        <img loading="lazy" className="gx-case-study-solutions-icon" src="/assets/images/welcomev2/case-study/sentinel-icon.png" alt="sentinelIcon" />
                                        <h1 className="gx-my-3">Advanced Attendance with Sentinel Devices</h1>
                                        <p className="gx-case-study-solutions_span">Our Sentinel IoT devices bring next-gen attendance tracking to Edbase. With support for QR codes, RFID, and face recognition (including proximity detection for contactless scans), Edbase can rely on fast, secure, cloud-based attendance tracking. Biometric registration is simple, enabling students to register on their phones with ease.</p>

                                        <div className="gx-case-study-solutions-card-image-right">
                                            <img loading="lazy" className="gx-case-study-solutions-right-image gx-case-study-solutions-group-phone-image" src="/assets/images/welcomev2/case-study/group-phone.png" alt="groupsImage" />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </section>

                {/* Impact Section */}
                <section className="gx-case-study-impact-section gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-case-study-impact-content gx-py-5">
                            <h1>The Impact</h1>
                            <p className="gx-case-study-impact_span">Our partnership with Edbase yielded impactful results, streamlining operations and creating a seamless, secure experience for students, teachers, and administrators. Here’s what Edbase achieved with Edutechs. Some of our key impact metrics include -</p>

                            <Row className="gx-py-5">
                                {
                                    impactsData?.map(impact =>
                                        <Col className="gx-my-2" xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <div className="gx-case-study-impact-card">
                                                <img loading="lazy" className="gx-my-1" src="/assets/images/welcomev2/case-study/correct-icon.png" alt="correctIcon" />
                                                <span className="gx-case-study-impact-card-span">{impact?.title}</span>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>

                            <div class="gx-use-case-impact-card gx-py-5">
                                <img loading="lazy" className="gx-use-case-impact-card-image1" src="/assets/images/welcomev2/case-study/impact1.png" alt="impactImage" />
                                <img loading="lazy" className="gx-use-case-impact-card-image2" src="/assets/images/welcomev2/case-study/impact2.png" alt="impactImage" />
                                <img loading="lazy" className="gx-use-case-impact-card-image3" src="/assets/images/welcomev2/case-study/impact3.png" alt="impactImage" />
                                <img loading="lazy" className="gx-use-case-impact-card-image4" src="/assets/images/welcomev2/case-study/impact4.png" alt="impactImage" />
                            </div>
                        </div>
                    </div>

                </section>
            </main>

            {/* Footer Section */}
            <div className="gx-case-study-footer">
                <Footer
                    heading="Ready to Elevate Your Institution?"
                    buttonText="Start For Free"
                    footerTop={true}
                />
            </div>

        </div>
    );
};

export default CaseStudyPage;