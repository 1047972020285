import firebase from "firebase";

// Initialize Firebase
const config = {
    apiKey              : process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain          : process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL         : process.env.REACT_APP_DATABASE_URL,
    projectId           : process.env.REACT_APP_PROJECT_ID,
    storageBucket       : process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId   : process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId               : process.env.REACT_APP_APP_ID,
    measurementId       : process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(config);

const auth                          = firebase.auth();
const database                      = firebase.database();


export {
  database,
  auth
};
