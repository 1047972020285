


const errorBoundaryDiscordDevChannel_Edutechs_Webhook       = 'https://discord.com/api/webhooks/1284205213972172883/rUWybLdXXJNDpyOOndH56zIAxPIH6bSU1iyndhWnmSeRZINm_vspeoO2mEu6_2pzGddY';
const signUpDiscordDevChannel_Edutechs_Webhook              = 'https://discord.com/api/webhooks/1288089180655718422/RQxkDX79GRYaZ4XOwiKPNJv9yM8_zPoHBz0KMztrEIxtyZQxtQRE3jifhvpz7iU9A_s6';

class Discord {


    logErrorToDiscord = ({
        errorMsg, traceID, sessionID
    }: {errorMsg: string, traceID: string, sessionID: string}) => {

        if (window.location.hostname === 'localhost') {
            //do not report if running on localhost
            return false;
        }
    
        let msg = `Error in Component: ${traceID} \n\n Component Session ID: ${sessionID} \n\n ${errorMsg}`;

        let formattedMSG = "```" + msg + "```";

        fetch(errorBoundaryDiscordDevChannel_Edutechs_Webhook, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                content: formattedMSG,
            }),
        })
        .then((response) => {
            console.log('response: ', response);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    logNewSignUpToDiscord = ({
        userName, accountType, userEmail, institutionName, phone
    }: {userName: string, accountType: string, userEmail: string, institutionName: string, phone: string}) => {

        if (window.location.hostname === 'localhost') {
            //do not report if running on localhost
            return false;
        }
    
        let msg = `New Sign-Up: Name: ${userName} \n\n -Type: ${accountType} \n\n -Email: ${userEmail} \n\n -Institution: ${institutionName} \n\n -Phone: ${phone}`;

        let formattedMSG = "```" + msg + "```";

        fetch(signUpDiscordDevChannel_Edutechs_Webhook, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                content: formattedMSG,
            }),
        })
        .then((response) => {
            console.log('response: ', response);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    reportGeneralError = ({
        errorMsg, errorObj
    }: {errorMsg: string, errorObj: Object}) => {
    
        let msg = `${errorMsg} - ${JSON.stringify(errorObj)}`;

        let formattedMSG = "```" + msg + "```";

        fetch(errorBoundaryDiscordDevChannel_Edutechs_Webhook, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                content: formattedMSG,
            }),
        })
        .then((response) => {
            console.log('response: ', response);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
}

export default new Discord()