import { useSelector } from "react-redux";
import { get } from "lodash-es";
import nameMappingForBusinessRoute from "./config";
import { nameMappingForCricketRoute } from "./config";
import { useTranslation } from "react-i18next";

const useBusinessModeMapper = (title) => {
    const { db } = useSelector(({ auth }) => auth);
    const { t }  = useTranslation();

    if (get(db, ["Preferences", "type"], undefined) === "CRICKET") {
        return t(nameMappingForCricketRoute[title]);
    }

    return get(db, ["ReactAppMode"], undefined) === "SMS_LMS_GATEWAY"
            ? t(title)
            : t(nameMappingForBusinessRoute[title]);
};

export default useBusinessModeMapper;
