import React from 'react';


import CurveImageSection from './CurveImageSection';
import CommunitySection from './CommunitySection';
import BlogSection from './BlogSection';
import CaseStudiesSection from './CaseStudiesSection';
// import Footer from './Footer';

import WelcomeHeader from './WelcomeHeader';
import BannerSection from './BannerSection';
import FeaturesSection from './FeaturesSection';
import PrivacySection from './PrivacySection';
import Footer from '../../components/Footer';

const WelcomeV2 = () => {




    return (
        <div className='gx-welcome-pagev2'>
            <WelcomeHeader />
            <main className="gx-main">
                <BannerSection />
                <CurveImageSection />
                <FeaturesSection />
                <CommunitySection />
                <PrivacySection />
                <BlogSection />
                <CaseStudiesSection />
            </main>
            {/* <Footer /> */}

            <Footer
                    heading="Give your classroom the upgrade it deserves"
                    buttonText="Start For Free"
                    footerTop={true}
                />
        </div>
    );
};

export default WelcomeV2;