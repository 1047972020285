import { set, get, unset, setWith } from "lodash-es";
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_PHONE_USER_SUCCESS,
  SIGNIN_PHONE_USER_SMS_CODE,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  UPDATE_SMS_SHARED_OBJ,
  UPDATE_MOD_TEACHER_GLOBAL_LIST_VOLATILE_STUDENT_IN_LOCAL_DB,
  FIRST_LOAD_PROGRESS_PERCENT,
  LOCAL_DB,
  UPDATE_IN_LOCAL_DB,
  UPDATE_NESTED_LOCAL_DB,
  REMOVE_IN_LOCAL_DB,
  UPDATE_MOD_TEACHER_IN_LOCAL_DB,
  UPDATE_MOD_TEACHER_IN_LOCAL_DB_FOR_RESTRICTED_DATA,
  UPDATE_STUDENT_IN_LOCAL_DB,
  UPDATE_ADMIN_TEACHER_IN_LOCAL_DB,
  UPDATE_OWN_DATA_MODERATOR,
  UPDATE_OWN_DATA_ADMIN,
  TEST_DB,
  ADD_PROGRESS_OBJECT,
  UPDATE_PROGRESS_OBJECT,
  Remove_All_PROGRESS_OBJECT,
  Remove_PROGRESS_OBJECT_BY_ID,
  SET_ACTIVE_TEACHER_UID,
  SET_ALL_TEACHERS_UID,
  UPDATE_SHARED_PREF_OBJ,
} from "../../constants/ActionTypes";


const returnAllStudentsAsOneUniqueList = (fullDB, filterTUID_BatchKeys) => {

    let outputAsArr     = [];
    let outputAsJSON    = {};
    let userClass       = get(fullDB, ['UserClass'], {});
    let thisTUID        = fullDB['UID'];

    for (let grade in userClass){
        for (let subject in userClass[grade]){
            for (let batch in userClass[grade][subject]['Streams']){

                if (filterTUID_BatchKeys){
                    //this means that a set of batch keys were passed in and only these are to be included
                    if (!filterTUID_BatchKeys.includes(`${thisTUID}-${batch}`)) continue;
                }

                let acceptedStudents = get(fullDB, ['UserClass', grade, subject, 'Streams', batch, 'AcceptedStudents'], undefined);

                if (acceptedStudents){
                    for (let oneSUID in acceptedStudents){

                        let dataToAdd               = {};

                        dataToAdd['Name']           = acceptedStudents[oneSUID]['Name'];
                        dataToAdd['Phone']          = acceptedStudents[oneSUID]['Phone'];
                        dataToAdd['UID']            = acceptedStudents[oneSUID]['UID'];
                        dataToAdd['Institution']    = acceptedStudents[oneSUID]['Institution'];
                        dataToAdd['Email']          = acceptedStudents[oneSUID]['Email'];
                        dataToAdd['avatarURL']      = acceptedStudents[oneSUID]['avatarURL'];
                        dataToAdd['customID']       = acceptedStudents[oneSUID]['customID'];

                        dataToAdd['parent1phone']   = acceptedStudents[oneSUID]['parent1phone'];
                        dataToAdd['parent2phone']   = acceptedStudents[oneSUID]['parent2phone'];
                        dataToAdd['RFIDCode']       = acceptedStudents[oneSUID]['RFIDCode'];

                        setWith(outputAsJSON, [oneSUID, 'AcceptedClasses', grade, subject, batch], acceptedStudents[oneSUID], Object);
                        set(outputAsJSON, [oneSUID] , {...acceptedStudents[oneSUID], ...outputAsJSON[oneSUID]});

                    }

                }

            }
        }
    }

    for (let oneSUID_ in outputAsJSON){
        outputAsArr.push(outputAsJSON[oneSUID_]);
    }

    //sort this array alphabetically
    outputAsArr?.sort((a, b) => a.Name?.localeCompare(b.Name));

    return ({outputAsArr, outputAsJSON});
}

const INIT_STATE = {
    loader: false,
    alertMessage: "",
    showMessage: false,
    initURL: "",
    progressData: [],
    authUser: localStorage.getItem("user_id"),
    test: 0,
    activeTeacherUID : '',
    allTeachersUID: [],
    db: undefined,
    firstLoadProgressPercent: 0,
    smsShared: {

    },
    sharedPreferences: {

    }
};

const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case SIGNIN_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case SIGNIN_PHONE_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case SIGNIN_PHONE_USER_SMS_CODE: {
        return {
            ...state,
            loader: false,
            confirmation: action.payload,
        };
        }
        case INIT_URL: {
        return {
            ...state,
            initURL: action.payload,
        };
        }
        case LOCAL_DB: {
        return {
            ...state,
            db: action.payload,
        };
        }
        case UPDATE_IN_LOCAL_DB: {
            //this overwrites the entire db
            let { objToAdd } = action.payload;

            return {
                ...state,
                db: objToAdd,
            };
        }

        case UPDATE_NESTED_LOCAL_DB: {
            //this merge updates the db at specific locations without overwrite
            let { objToAdd, nestedAddressArr } = action.payload;

            let currentDB               = { ...state['db'] };
            let existingData            = get(currentDB, nestedAddressArr, {});

            set(currentDB, nestedAddressArr, { ...existingData, ...objToAdd });

            return {
                ...state,
                db: currentDB,
            };
        }

        case UPDATE_OWN_DATA_MODERATOR: {
            //this only updates the moderator own local data
            let { objToAdd } = action.payload;

            let ownKeysToPreserve        = ['VolatileLocalData'];
            let currentDB                = { ...state['db'] };

            for (let oneKey of ownKeysToPreserve){
                objToAdd[oneKey] = get(currentDB, [oneKey], {});
            }

            return {
                ...state,
                db: objToAdd,
            };
        }

        case UPDATE_OWN_DATA_ADMIN: {
            //this only updates the admin own local data
            let { objToAdd } = action.payload;

            let ownKeysToPreserve        = ['VolatileLocalData', 'VolatileRestrictedData'];
            let currentDB                = { ...state['db'] };

            for (let oneKey of ownKeysToPreserve){
                objToAdd[oneKey] = get(currentDB, [oneKey], {});
            }

            return {
                ...state,
                db: objToAdd,
            };
        }

        case SET_ACTIVE_TEACHER_UID: {

        return {
            ...state,
            activeTeacherUID: action.payload.uid,
        };
        }

        case SET_ALL_TEACHERS_UID: {

        return {
            ...state,
            allTeachersUID: action.payload.allUid,
        };
        }

        case UPDATE_MOD_TEACHER_IN_LOCAL_DB: {

            let { objToAdd } = action.payload;

            let currentDB = { ...state['db'] };
    
            let existingData = get(currentDB, ['VolatileLocalData', 'Moderating'], {});
    
    
            set(currentDB, ['VolatileLocalData', 'Moderating'], { ...existingData, ...objToAdd });
    
    
            return {
            ...state,
            db: currentDB,
            };
        }
        case UPDATE_MOD_TEACHER_IN_LOCAL_DB_FOR_RESTRICTED_DATA: {

            let { objToAdd } = action.payload;

            let currentDB = { ...state['db'] };
    
            let existingData = get(currentDB, ['VolatileRestrictedData', 'Moderating'], {});
    
            set(currentDB, ['VolatileRestrictedData', 'Moderating'], { ...existingData, ...objToAdd });
    
    
            return {
            ...state,
            db: currentDB,
            };
        }
        case UPDATE_MOD_TEACHER_GLOBAL_LIST_VOLATILE_STUDENT_IN_LOCAL_DB: {
            //this will loop through the whole local db of teachers under moderators account and setup the global student list under each teacher

            let currentDB           = { ...state['db'] };
            let currentAllTeachers  = get(currentDB, ['VolatileLocalData', 'Moderating'], {});

            for (let oneTUID in currentAllTeachers){
                let thisTUID_FULL_DB = currentAllTeachers[oneTUID];

                let {outputAsArr, outputAsJSON} = returnAllStudentsAsOneUniqueList(thisTUID_FULL_DB);

                set(currentDB, ['VolatileLocalData', 'Moderating', oneTUID, 'VolatileLocalData', 'UniqueStudentAlphabeticalList'], outputAsArr);
                set(currentDB, ['VolatileLocalData', 'Moderating', oneTUID, 'VolatileLocalData', 'UniqueStudentJSONList'], outputAsJSON);
            }

            return {
                ...state,
                db: currentDB,
            };
        }
        case UPDATE_STUDENT_IN_LOCAL_DB: {

            let { objToAdd, locationArr } = action.payload;
    
            let currentDB = { ...state['db'] };
            let existingData = get(currentDB, [...['VolatileLocalData', 'TaughtBy'], ...locationArr], {});
    
            set(currentDB, [...['VolatileLocalData', 'TaughtBy'], ...locationArr], { ...existingData, ...objToAdd });
            
            return {
            ...state,
            db: currentDB,
            };
        }
        case UPDATE_ADMIN_TEACHER_IN_LOCAL_DB: {

        let { objToAdd } = action.payload;

        let currentDB = { ...state['db'] };

        let existingData = get(currentDB, ['VolatileLocalData', 'Admined'], {});


        set(currentDB, ['VolatileLocalData', 'Admined'], { ...existingData, ...objToAdd });


        return {
            ...state,
            db: currentDB,
        };
        }
        case REMOVE_IN_LOCAL_DB: {

        let { pathArr } = action.payload;

        let currentDB = Object.create(state.db);
        unset(currentDB, pathArr);

        return {
            ...state,
            db: currentDB,
        };
        }
        case TEST_DB: {
        return {
            ...state,
            test: action.payload,
        };
        }
        case SIGNOUT_USER_SUCCESS: {
        return {
            ...state,
            authUser: null,
            initURL: "/",
            loader: false,
            db: undefined
        };
        }

        case SHOW_MESSAGE: {
        return {
            ...state,
            alertMessage: action.payload,
            showMessage: true,
            loader: false,
        };
        }

        //ProcessBar reducer

        case FIRST_LOAD_PROGRESS_PERCENT: {
            let { percent } = action.payload;

            console.log('UPDATING PERCENTAGE +++++++ ', percent);
            return {
            ...state,
            firstLoadProgressPercent: percent
            };
        }

        case ADD_PROGRESS_OBJECT: {
        let currentProgressObj = state.progressData;
        currentProgressObj.push(action.payload);
        state.progressData = currentProgressObj;
        return {
            ...state,
            progress: action.payload,
        };
        }
        case UPDATE_PROGRESS_OBJECT: {
        let currentProgressObj = state.progressData;
        let { index, percent } = action.payload;

        currentProgressObj[index]["percentage"] = percent;
        state.progressData = currentProgressObj;

        return {
            ...state,
            progress: action.payload,
        };

        }

        case Remove_All_PROGRESS_OBJECT: {
        return {
            ...state,
            progressData: [],
        };
        }

        case Remove_PROGRESS_OBJECT_BY_ID: {
            let currentProgressObj = state.progressData;
            let { index } = action.payload;

            currentProgressObj.splice(index, 1);
            state.progressData = currentProgressObj;

            return {
                ...state,
                progress: action.payload,
            };
        }

        //Ends here
        case UPDATE_SMS_SHARED_OBJ: {
            let currentsmsSharedObjCOPY                     = {...state.smsShared};
            let { updatedData, nestedAddressArr }           = action.payload;
            set(currentsmsSharedObjCOPY, nestedAddressArr, updatedData);
            return {
                ...state,
                smsShared: {...state.smsShared, ...currentsmsSharedObjCOPY},
            };
        }

        case UPDATE_SHARED_PREF_OBJ: {
            let currentSharedObjCOPY                        = {...state.sharedPreferences};
            let { updatedData, nestedAddressArr }           = action.payload;
            set(currentSharedObjCOPY, nestedAddressArr, updatedData);
            return {
                ...state,
                sharedPreferences: {...state.sharedPreferences, ...currentSharedObjCOPY},
            };
        }

        case HIDE_MESSAGE: {
        return {
            ...state,
            alertMessage: "",
            showMessage: false,
            loader: false,
        };
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
        }
        case ON_SHOW_LOADER: {
        return {
            ...state,
            loader: true,
        };
        }
        case ON_HIDE_LOADER: {
        return {
            ...state,
            loader: false,
        };
        }

        default:
        return state;
    }
};

export default AuthReducer;