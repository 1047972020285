import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const Footer = ({ heading, buttonText, footerTop }) => {
    const history = useHistory();
    const themeType = useSelector(({ settings }) => settings.themeType);
    return (
        <footer className="gx-footer">
            <div className="gx-landing-container gx-footer-content-wrapper">
                {
                    footerTop &&
                    <div className="gx-footer-top gx-case-study-page-footer-top gx-case-study-footer-top">
                        <h2 className="gx-case-study-footer-heading">
                            {heading || "Give your classroom the upgrade it deserves"}
                        </h2>
                        <button onClick={() => history.push('/signup')} className="gx-case-study-footer-cta">{buttonText || "Start For Free"}</button>
                    </div>
                }
                <div className={`gx-footer-bottom ${footerTop && "gx-case-study-page-footer-bottom"}`}>
                    <div className="gx-footer-left">
                        <img src={`/assets/images/welcomev2/logo-white.png`} alt="Edutechs Logo" className="gx-footer-logo" />
                        <Select
                            suffixIcon={<span className="gx-custom-arrow">▼</span>}
                            className="gx-language-selector"
                            defaultValue="English"
                        >
                            <Select.Option value="English">English</Select.Option>
                            <Select.Option value="Bangla">Bangla</Select.Option>

                        </Select>
                    </div>

                    <div className="gx-footer-links">
                        <div className="gx-footer-column">
                            <h4>Company</h4>
                            <ul>
                                <li><Link to="/blog">News / Blog</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <h4>Products</h4>
                            <ul>
                                <li><Link to="/welcome">Edutechs LMS</Link></li>
                                <li>
                                    <Link to="/ai">Edutechs AI <span className="gx-new">NEW</span></Link>
                                </li>
                                <li><Link to="/sentinel">Sentinel – Student Safety</Link></li>
                                <li><Link to="/ex/sms">Bulk SMS Gateway</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <h4>Use Cases</h4>
                            <ul>
                                <li><Link to="/welcome">Education Management</Link></li>
                                <li><Link to="/sentinel">Attendance Automation</Link></li>
                            </ul>
                        </div>

                        <div className="gx-footer-column">
                            <h4>Community</h4>
                            <ul>
                            <li><a href="https://www.facebook.com/edutechs.org" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                            <li><a href="https://www.linkedin.com/company/edutechbd/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="gx-footer-copyright">
                    <span>© Edutechs</span>
                    <div>
                    <Link to="/Terms">Terms of Service</Link>
                    <Link to="/Privacy">Privacy Policy</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;