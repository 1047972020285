
import { Row, Col, Card, message, Result, Skeleton, Image, Popover, Button, Progress, Input, Upload, DatePicker, Select, Checkbox, Popconfirm, Modal } from "antd";
import { useEffect, useState, useRef } from "react";
import firebase from "firebase";
import ImgCrop from "antd-img-crop";

import { get, isEmpty } from "lodash-es";
import { CheckOutlined, DeleteOutlined, UploadOutlined, WarningOutlined } from '@ant-design/icons'
import { decodeString, getUrlVars } from "../../../util/misc";

import CustomScrollbars from "../../../util/CustomScrollbars";
import { useDispatch, useSelector } from "react-redux";
import languageData from "../../Topbar/languageData";
import { switchLanguage } from "../../../appRedux/actions";

import { useTranslation } from "react-i18next";
import IntlMessages from "../../../util/IntlMessages";
import moment from "moment";
import EdutechCloudServer from "../../../EdutechCloudServer";
import axios from "axios";
const { TextArea } = Input;


const database = firebase.database();


const OnlineAdmissionStudent = () => {

    const dispatch                                                      = useDispatch();
    const { t }                                                         = useTranslation();
    const locale                                                        = useSelector(({ settings }) => settings.locale);
    const [loading, setLoading]                                         = useState(true);
    const [submitLoading, setSubmitLoading]                             = useState(false)
    const [isError, setIsError]                                         = useState(false);
    const [masterUID, setMasterUID]                                     = useState(undefined);
    const [masterAccInfo, setMasterAccInfo]                             = useState({});

    const attachments                                                   = useRef([])

    const [isStartToFill, setIsStartToFill]                             = useState(false)
    const [progressPercent, setProgressPercent]                         = useState(0);
    const totalStep                                                     = useRef(7)
    const [stepCount, setStepCount]                                     = useState(0);
    const [studentPhoneError, setStudentPhoneError]                     = useState('');
    const [parent1PhoneError, setParent1PhoneError]                     = useState('');
    const [parent2PhoneError, setParent2PhoneError]                     = useState('');

    const [fullName, setFullName]                                       = useState('');
    const [phone, setPhone]                                             = useState('');
    // const [email, setEmail]                                             = useState('');
    const [studentPic, setStudentPic]                                   = useState(undefined)
    const [uploadPicText, setUploadPicText]                             = useState('');
    const [parent1Phone, setParent1Phone]                               = useState('')
    const [parent2Phone, setParent2Phone]                               = useState('');
    const [bloodGroup, setBloodGroup]                                   = useState('A+');
    const [dateOfBirth, setDateOfBirth]                                 = useState('');
    const [address, setAddress]                                         = useState('');
    // const [birthRegistrationNumber, setBirthRegistrationNumber]         = useState('');
    const [facebookProfile, setFacebookProfile]                         = useState('');
    const [classroomText, setClassroomText]                             = useState('');

    const previous90DaysApplication                                     = useRef([]);
    const [isMarkedDuplicatePhone, setIsMarkedPhoneDuplicate]           = useState(false)

    const [isNumberOnconfirmOpen, setIsNumberOnconfirmOpen]             = useState(false)
    const [languageDefault, setLanguageDefault]                         = useState();
    const [isSubmitSuccess, setIsSubmitSuccess]                         = useState(false)
    const [submittedApplicationKey, setSubmittedApplicationKey]         = useState('')


    useEffect(() => {

        if (get(masterAccInfo, ['OnlineAdmissionSettings', "language"], 'English') === 'Bangla') {
            localStorage.setItem('ln', 'bn')
            dispatch(switchLanguage({
                languageId: 'bangla',
                locale: 'bn',
                name: 'Bangla',
                icon: 'bd'
            }))
            setLanguageDefault('Bangla')
        }
        else if (get(masterAccInfo, ['OnlineAdmissionSettings', "language"], 'English') === 'English') {
            localStorage.setItem('ln', 'en')
            dispatch(switchLanguage({
                languageId: 'english',
                locale: 'en',
                name: 'English',
                icon: 'us'
            }))
            setLanguageDefault('English')
        }
        else if (localStorage.getItem('ln')) {
            if (localStorage.getItem('ln') === 'bn') {
                localStorage.setItem('ln', 'bn')
                dispatch(switchLanguage({
                    languageId: 'bangla',
                    locale: 'bn',
                    name: 'Bangla',
                    icon: 'bd'
                }))
                setLanguageDefault('Bangla')
            }
            else {
                localStorage.setItem('ln', 'en')
                dispatch(switchLanguage({
                    languageId: 'english',
                    locale: 'en',
                    name: 'English',
                    icon: 'us'
                }))
                setLanguageDefault('English')
            }
        }
        else {
            localStorage.setItem('ln', 'en')
            dispatch(switchLanguage({
                languageId: 'english',
                locale: 'en',
                name: 'English',
                icon: 'us'
            }))
            setLanguageDefault('English')
        }


    }, [masterAccInfo, dispatch])


    useEffect(() => {

        let urlParams = getUrlVars(window.location.href);

        if (!urlParams['key']) {
            setIsError(true);
            message.error('Invalid Link. Please check the link is correct and try again..');
            setLoading(false);
            return () => { }
        }

        let query = urlParams['key'];
        const thisUID = query;

        if (thisUID) {

            setMasterUID(thisUID)

            async function fetchMasterAccountInfo(uid) {
                try {
                    const snapshot = await database.ref(`USERS/${uid}`).once('value');
                    return snapshot.val(); // Return the fetched value
                } catch (error) {
                    console.error("Error fetching online admission settings:", error);
                    return null;
                }
            }

            async function handleMasterAccount() {

                const response = await fetchMasterAccountInfo(thisUID);

                if (response) {

                    setMasterAccInfo(response)
                    const admissionAttachments = get(response, ['OnlineAdmissionSettings', 'Attachments'], {})

                    let attachmentsArr = []

                    for (let key in admissionAttachments) {
                        let oneAttachments = admissionAttachments[key]
                        attachmentsArr.push(oneAttachments)
                    }

                    attachments.current = attachmentsArr;

                    setLoading(false)
                }
                else {
                    console.log("No settings found or an error occurred.");
                }


            }

            handleMasterAccount()


            async function fetchPrevious90DaysData() {
                const now = Date.now();
                const ninetyDaysAgo = now - (90 * 24 * 60 * 60 * 1000); // 90 days in milliseconds

                const applicationPath = database.ref(`OnlineAdmissionApplication/${thisUID}/PendingApplication`);

                applicationPath.orderByChild('timeStamp').startAt(ninetyDaysAgo).endAt(now).on('value', (snapshot) => {

                    const previousData = snapshot.val();

                    if (previousData) {
                        let applicationArray = []

                        for (let key in previousData) {

                            let oneData = get(previousData, [key], {});

                            if (oneData['Phone']) {
                                applicationArray.push(oneData)
                            }
                            else {
                                continue;
                            }

                        }

                        previous90DaysApplication.current = applicationArray;
                    }
                    else {
                        console.log('previous data not available')
                    }
                });
            }


            fetchPrevious90DaysData(); //fetch previous 90 days application


        }
        else {
            setIsError(true);
            message.error('Invalid Link. Please check the link is correct and try again..');
            setLoading(false);
        }

        return () => {
            database.ref(`USERS/${thisUID}/`).off();
        }


    }, [isSubmitSuccess]);


    const changePageToAhead = ({ page }) => {
        if (page === '1') {
            setIsStartToFill(true)
            setProgressPercent(prev => prev + 100 / totalStep.current)
            setStepCount(prev => prev + 1)
        }
        else {
            setProgressPercent(prev => prev + 100 / totalStep.current)
            setStepCount(prev => prev + 1)
        }
    }

    const validatePhoneNumber = (value, type) => {
        const phonePattern = /^\d{11,15}$/;
        if (value === '') {
          if (type === 'studentPhone') {
            setStudentPhoneError('');
          } else if (type === 'parent1Phone') {
            setParent1PhoneError('');
          } else if (type === 'parent2Phone') {
            setParent2PhoneError('');
          }
        } else if (!phonePattern.test(value)) {
          if (type === 'studentPhone') {
            setStudentPhoneError('Please enter a valid phone number with 11 to 15 digits');
          } else if (type === 'parent1Phone') {
            setParent1PhoneError('Please enter a valid phone number with 11 to 15 digits');
          } else if (type === 'parent2Phone') {
            setParent2PhoneError('Please enter a valid phone number with 11 to 15 digits');
          }
        } else {
          if (type === 'studentPhone') {
            setStudentPhoneError('');
          } else if (type === 'parent1Phone') {
            setParent1PhoneError('');
          } else if (type === 'parent2Phone') {
            setParent2PhoneError('');
          }
        }
      };
    
      const handlePhoneInputChange = (e, type) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
          validatePhoneNumber(value, type);
          if (type === 'studentPhone') {
            setPhone(value);
          } else if (type === 'parent1Phone') {
            setParent1Phone(value);
          } else if (type === 'parent2Phone') {
            setParent2Phone(value);
          }
        } else {
          if (type === 'studentPhone') {
            setStudentPhoneError('Please enter only digits');
          } else if (type === 'parent1Phone') {
            setParent1PhoneError('Please enter only digits');
          } else if (type === 'parent2Phone') {
            setParent2PhoneError('Please enter only digits');
          }
        }
      };


    const uploadStudentPhoto = async ({ photo, uid, loadKey, callback }) => {


        message.loading(`Uploading Student Picture..`, loadKey);
        EdutechCloudServer.postRequest('upload-avatar', {}, async (res) => {
            if (res.msg === 'success') {

                let uploadUrl = res.uploadUrl;
                let uploadAuthorizationToken = res.uploadAuthorizationToken;
                let filename = `${uid}-avatar.png`;

                await axios.post(uploadUrl, photo, {
                    withCredentials: true,
                    headers: {
                        Authorization: uploadAuthorizationToken,
                        'Content-Type': 'b2/x-auto',
                        'X-Bz-File-Name': `${filename}`,
                        'X-Bz-Content-Sha1': 'do_not_verify',
                    },
                    onUploadProgress: ({ loaded, total }) => {
                        const totalProgress = parseInt((loaded / total) * 100);
                        message.loading(`Uploading Student Picture ${totalProgress}%`, loadKey);
                    }
                })

                let friendlyURL = `https://f002.backblazeb2.com/file/Edutech-Avatars/${filename}`


                database.ref(`USERS/${uid}/PublicInfo`).update({
                    'avatarURL': friendlyURL
                })
                    .then(() => {
                        message.loading(`Student Picture uploaded successfully..`, loadKey);

                        callback(friendlyURL);
                    })
            }

            else {
                message.error('Image Upload Failed. Server could not provide valid upload token');
            }
        });

    }

    // check if phone used before or not
    const checkIfPhoneUsedBefore = ({ phone }) => {
        let valid       = true;
        let clash       = 'No Clash';
        let err         = 'No Error';
        let createdAt   = "No Created Before";
        let phoneMapper = {};


        for (let oneObj of previous90DaysApplication.current) {
            const thisPhone = get(oneObj, ['Phone'], '');
            const thisName  = get(oneObj, ['Name'], '');
            const thisTimeStamp = get(oneObj, ['timeStamp'], '');
            if (thisPhone !== '') {
                phoneMapper[thisPhone] = {
                    phone       : thisPhone,
                    name        : thisName,
                    createdAt   : thisTimeStamp
                }
            }
        }

        //if this students provided phone is already applied
        if (phoneMapper[phone]) {
            if (phoneMapper[phone]['phone'] === phone) {
                return { valid: false, err: 'phone', clash: phoneMapper[phone]['name'], createdAt: phoneMapper[phone]['createdAt'] }
            }
        }

        return { valid, clash, createdAt, err }
    }

    const handleSubmitApplication = () => {
        setSubmitLoading(true);
        const timestamp = new Date().getTime();
    
        const applicationData = {
            Name        : fullName,
            Phone       : isMarkedDuplicatePhone ? `${phone}D` : phone,
            classroom   : classroomText,
            timeStamp   : timestamp,
        };

        if(parent1Phone){
            applicationData['parent1phone'] = parent1Phone;
        }
        if(parent2Phone){
            applicationData['parent2phone'] = parent2Phone;
        }

        if(bloodGroup){
            applicationData['bloodGroup']   = bloodGroup;
        }
        if(dateOfBirth){
            applicationData['dateOfBirth']  = dateOfBirth;
        }
        if(address){
            applicationData['address']      = address;
        }
        if(facebookProfile){
            applicationData['facebookProfile'] = facebookProfile;
        }
    
        if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectParentsPhone'], false) === true) {
            if (isEmpty(parent1Phone) && isEmpty(parent2Phone)) {
                message.error("Please provide at least one parent's phone number");
                setSubmitLoading(false);
                return false;
            }
        }
        if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectAdditionalInfo'], false) === true) {
            if(bloodGroup === ''){
                message.error('Please provide your blood group');
                setSubmitLoading(false);
                return false
            }
            if(address === ''){
                message.error('Please provide your address');
                setSubmitLoading(false);
                return false
            }
            if(dateOfBirth === ''){
                message.error('Please provide your date of birth');
                setSubmitLoading(false);
                return false
            }

        }
    
        if (fullName === '' && phone === '' && classroomText === '') {
            message.error('please provide all information carefully');
            setSubmitLoading(false);
            return false;
        }
    
        const submitApplication = (data) => {
            const newEntryRef = database.ref(`OnlineAdmissionApplication/${masterUID}/PendingApplication`).push(data);
            const pushKey = newEntryRef.key;
    
            newEntryRef.then(() => {
                setSubmitLoading(false);
                setIsSubmitSuccess(true);
                // message.success('Your application successfully submitted');
    
                // Log the push key for reference
                console.log('Push key:', pushKey);
                setSubmittedApplicationKey(pushKey)
    
                // Reset form fields
                setIsStartToFill(false);
                setPhone('');
                setFullName('');
                setParent1Phone('');
                setParent2Phone('');
                setStudentPic('');
                setUploadPicText('');
                setStepCount(0);
                setBloodGroup('');
                setAddress('');
                setDateOfBirth('');
                setFacebookProfile('');
                setIsMarkedPhoneDuplicate(false);
                setClassroomText('');
            });
        };
    
        if (studentPic) {
            const loadingKey = 'loading-key';
            uploadStudentPhoto({
                photo: studentPic,
                uid: timestamp,
                loadKey: loadingKey,
                callback: (avatarURL) => {
                    console.log('avatarURL', avatarURL);
                    applicationData['avatarURL'] = avatarURL;
                    submitApplication(applicationData);
                }
            });
        } else {
            submitApplication(applicationData);
        }
    };
    
    const handleNumberOnconfirmOk = () => {
        setIsNumberOnconfirmOpen(false);
        changePageToAhead({ page: '3' });
    };
    
    const handleOnconfirmCancel = () => {
        setIsNumberOnconfirmOpen(false);
    };

    const handleNextClick = () => {
        if (phone.trim() === '') {
            if(languageDefault === 'English'){
                message.error('Please provide your phone number');
            }
            else{
                message.error("আপনার ফোন নম্বর প্রদান করুন।")
            }
        } 
        else {
            let { valid, err, clash, createdAt } = checkIfPhoneUsedBefore({ phone });
            if (!valid) {
                setIsMarkedPhoneDuplicate(true);
                setIsNumberOnconfirmOpen(true)
                if (err === 'phone') {
                //   message.error(`Your given phone number ${phone} is already used by ${clash} since ${moment(createdAt).format('DD MMMM, YYYY hh:mm A')}. Please use another or mark duplicate!`);
                }
            } else {
                setIsMarkedPhoneDuplicate(false);
                setIsNumberOnconfirmOpen(false)
                changePageToAhead({ page: '3' });
            }
        }
    };
    
 
    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
                {languageData.map(language =>
                    <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() => {
                        console.log('Switch to language: ', language);
                        dispatch(switchLanguage(language))
                        localStorage.setItem("locale", language.locale);
                        localStorage.setItem("ln", language.locale);
                        localStorage.setItem("lng", language.locale);
                    }

                    }>
                        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
                        <span className="gx-language-text">{language.name}</span>
                    </li>
                )}
            </ul>
        </CustomScrollbars>);

        console.log('get(masterA', get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectPicture'], false))

    return (

        <Row justify='center' align='middle' className='gx-p-0 gx-m-0' style={{ background: loading === true ? 'white' : 'black', height: '100svh' }}>
            {loading === true ?
                <Col xs={24} md={24} xl={24} key={'34aa'}>
                    <Row justify="center" align="middle" style={{ paddingTop: '5%' }}>
                        <Col xs={24} md={24} xl={24} key={'34a'}>
                            <Card>
                                <Skeleton active paragraph={{ rows: 20 }} />
                            </Card>
                        </Col>
                    </Row>
                </Col>

                :

                <>
                    {isError === true ?
                        <Result
                            style={{ height: '700px' }}
                            status="error"
                            title={<h2>Invalid Invoice Link</h2>}
                            subTitle={<p>
                                Please double-check your link and make sure you pasted it properly in the browser.
                            </p>}
                        >
                        </Result>

                        :

                        <Col xs={24} md={12} xl={6} style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative', padding:'0' }}>
                            {/* 1st div (Fixed at Top) */}
                            <div style={{ position: 'sticky', top: 0, width: '100%', background: '#f5f5f5', zIndex: 1 }}>
                                {isStartToFill === false ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                                        <Skeleton active loading={loading}>
                                            <div style={{ width: '100%', minHeight: '50px', gap: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }}>
                                                <div style={{ width: '65%', display: 'flex', alignItems: 'center' }}>
                                                    <h1 className="gx-fs-xxl gx-font-weight-small gx-mb-0 gx-text-deep-grey"><IntlMessages id="Online-Admission"/></h1>
                                                </div>
                                                <ul className="gx-header-notifications gx-ml-auto">
                                                    <li className="gx-language">
                                                        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()} trigger="click">
                                                            <span className="gx-pointer gx-flex-row gx-align-items-center" style={{ padding: '4px 12px', border: '1px solid #dedede', borderRadius: '5px' }}>
                                                                <span className="gx-language-namew">{locale.name === 'Bangla' ? 'বাংলা' : locale.name}</span>
                                                                <i className="iconz icon-chevron-down gx-pl-2 gx-mt-2" />
                                                            </span>
                                                        </Popover>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Skeleton>
                                    </div>
                                ) : (
                                    <div className='gx-my-auto gx-d-flex gx-align-items-center' style={{ minHeight: '40px', width: '100%', padding: '8px 16px', }}>
                                        <Progress className="gx-online-admission-progress-bar" percent={progressPercent} showInfo={false} />
                                        <p className="gx-text-deep-grey gx-mb-0">{stepCount}/{totalStep.current}</p>
                                    </div>
                                )}
                            </div>

                            {/* 2nd div (Scrollable Content) */}
                            <div className="scrollbarHide" style={{ flexGrow: 1, overflowY: 'auto', padding: '16px', background: '#f5f5f5' }}>
                                {
                                    isSubmitSuccess === true ? ( <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '100%', height:'100%', flexDirection: 'column' }}>
                                        <div className="gx-p-2 gx-text-center" style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                                            <div className="gx-rounded-circle gx-mb-3" style={{display:'flex', alignItems:'center', justifyContent:'center', background:'green', height:'7rem', width:'7rem', color: '#fff'}}>
                                                <CheckOutlined style={{color: '#fff', fontSize:'4rem'}} />
                                            </div>
                                            <h3 className="gx-text-center"><IntlMessages id="Success-Text"/></h3>
                                            <p><span className="gx-d-block gx-mb-2"><IntlMessages id="Application-ID"/>: {submittedApplicationKey}</span></p>

                                            <p><IntlMessages id="Keep-ID-Text"/></p>
                                        </div>
                                    </div>)
                                    : 
                                    <>
                                        {
                                            isStartToFill === false ? (
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '100%', flexDirection: 'column' }}>
                                                    <div className="gx-institute-details" style={{ textAlign: 'center' }}>
                                                        <Image
                                                            width={180}
                                                            key={`logo`}
                                                            src={get(masterAccInfo, ['PublicInfo', 'Brand', 'darkLogo'], undefined) === undefined ? decodeString(get(masterAccInfo, ['PublicInfo', 'avatarURL'], 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png')) : decodeString(get(masterAccInfo, ['PublicInfo', 'Brand', 'darkLogo'], 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png'))}
                                                            preview={false}
                                                            style={{ borderRadius: '10px', marginTop: '30px', marginBottom: '20px' }}
                                                        />
                                                        <h1 className='gx-fs-xxxl gx-text-deep-grey gx-font-weight-medium gx-px-1 gx-mt-3'>{decodeString(get(masterAccInfo, ['PublicInfo', 'UserInstitution'], ''))}</h1>
                                                        <p className="gx-text-grey gx-mt-3 gx-fs-md" style={{ lineHeight: '22px' }}><IntlMessages id="Admission-Welcome-Text"/></p>
                                                    </div>

                                                    {
                                                        attachments.current.length > 0 ?
                                                        <>
                                                            <div className='gx-mt-2' style={{ width: '100%' }}>
                                                                <div style={{ background: '#e7e7e7', width: '100%', height: '2px' }}></div>
                                                            </div>
                                                            <div className="gx-admission-attachments">
                                                                <p className="gx-text-grey gx-text-center gx-mt-4">
                                                                <IntlMessages id="Attachments-Text"/>
                                                                </p>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                    {attachments.current.map((file, i) => (
                                                                        <a target="_blank" rel="noopener noreferrer" href={file['url']} key={i}>
                                                                            {file['name']}
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    {/* <div className="gx-next-btn-wrapper gx-mt-5" style={{ width: '100%' }}>
                                                        <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => changePageToAhead({ page: '1' })}><IntlMessages id="Lets-Go"/>!</Button>
                                                    </div> */}
                                                    
                                                </div>
                                            ) :
                                                (
                                                    <>
                                                        {   // student name
                                                            stepCount === 1 ?
                                                                <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ width: '100%' }}>
                                                                        <p className="gx-fs-lg gx-text-grey" style={{ lineHeight: '22px' }}><IntlMessages id="Name-Text"/></p>
                                                                        <Input placeholder="John Doe" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                                                    </div>
                                                                    <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }}>
                                                                        <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                            if (fullName !== '') {
                                                                                changePageToAhead({ page: '2' })
                                                                            }
                                                                            else {
                                                                                if(languageDefault === 'English'){
                                                                                    message.error('Please provide your name.')
                                                                                }
                                                                                else{
                                                                                    message.error('আপনার নাম প্রদান করুন।')
                                                                                }
                                                                            }
                                                                        }}><IntlMessages id="Next"/></Button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    { // student phone number
                                                                        stepCount === 2 ?
                                                                            <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                                <div style={{ width: '100%' }}>
                                                                                    <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}><IntlMessages id="appModule.hello"/> <span className="gx-font-weight-medium gx-text-dark-grey">{fullName}</span>
                                                                                        <span className="gx-d-block gx-mt-1"><IntlMessages id="Share-Phone-Text"/></span>
                                                                                    
                                                                                        </p> 
                                                                                    <Input prefix="+88" placeholder="01XXX XXXXXX" maxLength={15} minLength={11} value={phone} onChange={(e) => {
                                                                                        setIsMarkedPhoneDuplicate(false)
                                                                                        handlePhoneInputChange(e, 'studentPhone')
                                                                                    }} />
                                                                                    {studentPhoneError && <div style={{ marginTop: '10px', color: 'red' }}>{<IntlMessages id="Phone-Error"/>}</div>}
                                                                                </div>
                                                                                <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }} >
                                                                                
                                                                            
                                                                                        <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={()=> handleNextClick()}>
                                                                                            <IntlMessages id="Next"/>
                                                                                        </Button>
            
                                                                                        <Modal
                                                                                            title           = {<span><WarningOutlined style={{color:'#cc3300'}} />  <IntlMessages id="Duplicate-Phone"/>!</span>}
                                                                                            visible         = {isNumberOnconfirmOpen}
                                                                                            onCancel        = {handleOnconfirmCancel}
                                                                                            wrapClassName   = {'PromptWithMultiOptions'}
                                                                                            footer={[
                                                                                            <Button key="cancel" onClick={handleOnconfirmCancel}>
                                                                                                <IntlMessages id="button.no"/>
                                                                                            </Button>,
                                                                                            <Button key="ok" type="primary" onClick={handleNumberOnconfirmOk}>
                                                                                            <IntlMessages id="button.yes"/>
                                                                                            </Button>,
                                                                                            ]}
                                                                                        >
                                                                                            <IntlMessages id="Duplicate-Phone-Text"/>
                                                                                        </Modal>
                                                                            
                                                                                    <Button type="default" className='gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                        setStepCount(prev => prev - 1)
                                                                                        setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                    }}><IntlMessages id="appModule.back"/></Button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {/* { // student email
                                                                                skipToCollectEmail.current === false && (stepCount === 3 && get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectEmail'], false) === true)  ?
                                                                                <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                                    <div style={{ width: '100%' }}>
                                                                                        <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}>Can you also share your email address? By the way, this is optional!</p>
                                                                                        <Input placeholder="johndoe@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                                                        {emailError && <div style={{ marginTop: '10px', color: 'red' }}>{emailError}</div>}
                                                                                    </div>
                                                                                    <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }}>
                                                                                        <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => changePageToAhead({ page: '4' })}>Next</Button>
                                                                                        <Button type="default" className='gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                            // if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectEmail'], false) === false) {
                                                                                            //     setProgressPercent(prev => prev - 100 / totalStep.current)
                                                                                            //     setStepCount(prev => prev - 2)
                                                                                            // }
                                                                                            setStepCount(prev => prev - 1)
                                                                                            setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                        }}>Back</Button>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <> */}
                                                                                { // student avatar
                                                                                    parseInt(stepCount) === 3 ?
                                                                                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                                            <div style={{ width: '100%' }}>
                                                                                                <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}><span className="gx-d-block"><IntlMessages id="Great"/>!</span> <IntlMessages id="Picture-Text"/></p>
                                                                                                <ImgCrop rotate fillColor={'rgba(0,0,0,0)'}>
                                                                                                    <Upload
                                                                                                        className="gx-online-admission-upload"
                                                                                                        style={{ width: '100%!important' }}
                                                                                                        name="StudentPicture"
                                                                                                        maxCount={1}
                                                                                                        action={(file) => console.log(file)}
                                                                                                        showUploadList={false}
                                                                                                        customRequest={(x) => {
                                                                                                            setStudentPic(x.file);
                                                                                                            setUploadPicText(x.file.name);
                                                                                                        }}
                                                                                                        listType="picture"
                                                                                                        beforeUpload={(file) => {
                                                                                                            const isPNG = (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg');
                                                                                                            if (!isPNG) {
                                                                                                                message.error(`${file.name} is not a png, jpg or jpeg file`);
                                                                                                            }
                                                                                                            return isPNG || Upload.LIST_IGNORE;
                                                                                                        }}
                                                                                                    >
                                                                                                        <Button className="gx-w-100 gx-d-block" icon={<UploadOutlined />} type="dashed">{uploadPicText ? uploadPicText : <IntlMessages id="Upload-Picture"/>}
                                                                                                            {
                                                                                                                studentPic ?
                                                                                                                    <span style={{ display: 'block', position: 'absolute', right: '10px', top: '0px' }}>
                                                                                                                        <DeleteOutlined
                                                                                                                            onClick={(e) => {
                                                                                                                                e.stopPropagation()
                                                                                                                                setStudentPic(undefined);
                                                                                                                                setUploadPicText('Click to upload');
                                                                                                                            }
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                    : <></>
                                                                                                            }
                
                                                                                                        </Button>
                
                                                                                                    </Upload>
                                                                                                </ImgCrop>
            
            
                                                                                            </div>
                                                                                            <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }}>
                                                                                                <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                 
                                                                                                    if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectPicture'], false) === true) {
                                                                                                        if (studentPic !== undefined) {
                                                                                                            changePageToAhead({ page: '5' })
                                                                                                        }
                                                                                                        else {
                                                                                                            if(languageDefault === 'English'){
                                                                                                                message.error('Please provide your picture')
                                                                                                            }
                                                                                                            else{
                                                                                                                message.error('আপনার ছবি প্রদান করুন।')
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        changePageToAhead({ page: '4' })
                                                                                                    }
                                                                                                    }}><IntlMessages id="Next"/></Button>
                                                                                                <Button type="default" className='gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                    // if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectEmail'], false) === false) {
                                                                                                    //     setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                                    //     setStepCount(prev => parseInt(prev) - 1)
                                                                                                    // }
                                                                                                    setStepCount(prev => prev - 1)
                                                                                                    setProgressPercent(prev => prev - 100 / totalStep.current)
                                                                                                }}><IntlMessages id="appModule.back"/></Button>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                stepCount === 4 ?
                                                                                                    <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                                                        <div style={{ width: '100%' }}>
                                                                                                            <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}><IntlMessages id="Parent-Phone-Text"/><span className="gx-d-block">{get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectParentsPhone'], false) === true ? '' : ''}</span></p>
                                                                                                            <span className="gx-d-block gx-mb-2"><IntlMessages id="Parent1Phone"/></span>
                                                                                                            <Input prefix="+88" placeholder="01XXX XXXXXX" maxLength={15} minLength={11} value={parent1Phone} onChange={(e) => handlePhoneInputChange(e, 'parent1Phone')} />
                                                                                                            {parent1PhoneError && <div style={{ marginTop: '10px', color: 'red' }}>{<IntlMessages id="Phone-Error"/>}</div>}
            
                                                                                                            <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Parent1Phone"/></span>
                                                                                                            <Input prefix="+88" placeholder="01XXX XXXXXX" maxLength={15} minLength={11} value={parent2Phone} onChange={(e) => handlePhoneInputChange(e, 'parent2Phone')} />
                                                                                                            {parent2PhoneError && <div style={{ marginTop: '10px', color: 'red' }}>{<IntlMessages id="Phone-Error"/>}</div>}
                                                                                                        </div>
                                                                                                        <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }}>
                                                                                                            <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                                if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectParentsPhone'], false) === true) {
                                                                                                                    if (parent1Phone !== '' || parent2Phone !== '') {
                                                                                                                        if(get(masterAccInfo, ['OnlineAdmissionSettings', 'RequireUniqueParentsPhone'], false) === true){
                                                                                                                            if(phone === parent1Phone || phone === parent2Phone){
                                                                                                                                if(languageDefault === 'English'){
                                                                                                                                    message.error('Please provide at least one unique phone number of the parent')
                                                                                                                                }
                                                                                                                                else{
                                                                                                                                    message.error('অনুগ্রহ করে পিতামাতার ন্যূনতম একটি ইউনিক ফোন নম্বর প্রদান করুন।')
                                                                                                                                }
                                                                                           
                                                                                                                            }
                                                                                                                            else if(phone === `${parent1Phone}D` || phone === `${parent2Phone}D`){
                                                                                                                                if(languageDefault === 'English'){
                                                                                                                                    message.error('Please provide at least one unique phone number of the parent')
                                                                                                                                }
                                                                                                                                else{
                                                                                                                                    message.error('অনুগ্রহ করে পিতামাতার ন্যূনতম একটি ইউনিক ফোন নম্বর প্রদান করুন।')
                                                                                                                                }
                                                                                                                       
                                                                                                                            }
                                                                                                                            else{
                                                                                                                                changePageToAhead({ page: '5' })
                                                                                                                            }

                                                                                                                        }
                                                                                                                        else{
                                                                                                                            changePageToAhead({ page: '5' })
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        if(languageDefault === 'English'){
                                                                                                                            message.error('Please provide your parents phone number')
                                                                                                                        }
                                                                                                                        else{
                                                                                                                            message.error('আপনার পিতামাতার ফোন নম্বর প্রদান করুন।')
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    changePageToAhead({ page: '5' })
                                                                                                                }
                                                                                                            }}
                                                                                                            ><IntlMessages id="Next"/></Button>
                                                                                                            <Button type="default" className='gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                                // if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectParentsPhone'], false) === false) {
                                                                                                                //     setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                                                //     setStepCount(prev => parseInt(prev) - 2)
                                                                                                                // }
                                                                                                                setStepCount(prev => prev - 1)
                                                                                                                setProgressPercent(prev => prev - 100 / totalStep.current)
                                                                                                                setParent2PhoneError('')
                                                                                                                setParent1PhoneError('')
                                                                                                                setStudentPhoneError('')
                                                                                                            }}><IntlMessages id="appModule.back"/></Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <>
            
                                                                                                        {
                                                                                                            stepCount === 5 ?
                                                                                                                <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                                                                    <div style={{ width: '100%' }}>
                                                                                                                        <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}><IntlMessages id="Address-Text"/></p>
                                                                                                                        <span className="gx-d-block gx-mb-2"><IntlMessages id="Address"/></span>
                                                                                                                        <Input placeholder={languageDefault === 'English' ? 'Enter your address': 'আপনার ঠিকানা লিখুন'} value={address} onChange={(e) => setAddress(e.target.value)} />
                                                                                                                        <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Date-Of-Birth"/></span>
                                                                                                                        <DatePicker
                                                                                                                            style={{ width: '100%' }}
                                                                                                                            format={'DD-MM-YYYY'}
                                                                                                                            onChange={(v) => setDateOfBirth(moment(v).format('DD-MM-YYYY'))}
                                                                                                                        />
                                                                                                                        <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Blood-Group"/></span>
                                                                                                                        <Select style={{ width: '100%' }} value={bloodGroup} onChange={(v) => setBloodGroup(v)}>
                                                                                                                            <Select.Option value="A+">A+</Select.Option>
                                                                                                                            <Select.Option value="A-">A-</Select.Option>
                                                                                                                            <Select.Option value="B+">B+</Select.Option>
                                                                                                                            <Select.Option value="B-">B-</Select.Option>
                                                                                                                            <Select.Option value="AB+">AB+</Select.Option>
                                                                                                                            <Select.Option value="AB-">AB-</Select.Option>
                                                                                                                            <Select.Option value="O+">O+</Select.Option>
                                                                                                                            <Select.Option value="O-">O-</Select.Option>
                                                                                                                        </Select>
            
                                                                                                                        {/* <span className="gx-d-block gx-mb-2 gx-mt-3">Birth Certificate Number</span>
                                                                                                                            <Input placeholder="15XX XXXX 45XX 8XX4X" value={birthRegistrationNumber} onChange={(e) => setBirthRegistrationNumber(e.target.value)} /> */}
                                                                                                                        <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Facebook-Profile"/></span>
                                                                                                                        <Input placeholder="www.facebook.com/shaker87" value={facebookProfile} onChange={(e) => setFacebookProfile(e.target.value)} />
                                                                                                                    </div>
                                                                                                                    <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }}>
                                                                                                                        <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                                            if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectAdditionalInfo'], false) === true) {
                                                                                                                                if (address !== '' && dateOfBirth !== '' && bloodGroup !== '') {
                                                                                                                                    changePageToAhead({ page: '6' })
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                    if(languageDefault === 'English'){
                                                                                                                                        message.error('Please provide additional information')
                                                                                                                                    }
                                                                                                                                    else{
                                                                                                                                        message.error('অতিরিক্ত তথ্য প্রদান করুন।')
                                                                                                                                    }
                                                                                                                                }
            
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                changePageToAhead({ page: '6' })
                                                                                                                            }
                                                                                                                        }}><IntlMessages id="Next"/></Button>
                                                                                                                        <Button type="default" className='gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                                            // if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectParentsPhone'], false) === false) {
                                                                                                                            //     setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                                                            //     setStepCount(prev => parseInt(prev) - 2)
                                                                                                                            //     return false;
                                                                                                                            // }
                                                                                                                            setStepCount(prev => prev - 1)
                                                                                                                            setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                                                        }}><IntlMessages id="appModule.back"/></Button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        stepCount === 6 ?
                                                                                                                            <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', height: '100%' }}>
                                                                                                                                <div style={{ width: '100%' }}>
                                                                                                                                    <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}><IntlMessages id="Classroom-Text"/></p>
                                                                                                                                    <TextArea rows={4} placeholder="eg. Class 10 - Section 1 - Physics" value={classroomText} onChange={(e) => setClassroomText(e.target.value)} />
                                                                                                                                </div>
                                                                                                                                <div className="gx-next-btn-wrapper gx-mt-3" style={{ width: '100%' }}>
                                                                                                                                    <Button className='gx-btn-primary gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                                                        if (classroomText !== '') {
                                                                                                                                            changePageToAhead({ page: '7' })
                                                                                                                                        }
                                                                                                                                        else {
                                                                                                                                            if(languageDefault === 'English'){
                                                                                                                                                message.error("Please write details")
                                                                                                                                            }
                                                                                                                                            else{
                                                                                                                                                message.error('বিস্তারিত লিখুন')
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }}><IntlMessages id="Review"/></Button>
                                                                                                                                    <Button type="default" className='gx-px-5 gx-w-100 gx-mb-2 gx-d-block' onClick={() => {
                                                                                                                                        // if (get(masterAccInfo, ['OnlineAdmissionSettings', 'CollectAdditionalInfo'], false) === false) {
                                                                                                                                        //     setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                                                                        //     setStepCount(prev => parseInt(prev) - 3)
                                                                                                                                        //     return false;
                                                                                                                                        // }
                                                                                                                                        setStepCount(prev => prev - 1)
                                                                                                                                        setProgressPercent(prev => prev - 100 / totalStep.current)
            
                                                                                                                                    }}><IntlMessages id="appModule.back"/></Button>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    stepCount === 7 ?
                                                                                                                                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: "center", width: '100%', flexDirection: 'column', marginTop: '5svh' }}>
                                                                                                                                            <div style={{ width: '100%' }}>
                                                                                                                                                <p className="gx-fs-lg gx-text-grey gx-mb-4" style={{ lineHeight: '22px' }}><IntlMessages id="Review-Text"/></p>
                                                                                                                                                <Card className="gx-online-pay-card gx-py-0" style={{ marginBottom: '16px', background: '#fff', border: '1px solid #eeee' }}>
                                                                                                                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                                                                                                                        <img
                                                                                                                                                            style={{ width: '42px', minWidth: '42px', borderRadius: '50%', aspectRatio: 1 }}
                                                                                                                                                            src={get(masterAccInfo, ['PublicInfo', 'Brand', 'darkLogo'], undefined) === undefined ? decodeString(get(masterAccInfo, ['PublicInfo', 'avatarURL'], 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png')) : decodeString(get(masterAccInfo, ['PublicInfo', 'Brand', 'darkLogo'], 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png'))}
                                                                                                                                                            alt="Brand"
                                                                                                                                                            srcSet=""
                                                                                                                                                        />
                                                                                                                                                        <div className="gx-pl-2 gx-fs-lg gx-font-weight-medium">{decodeString(get(masterAccInfo, ['PublicInfo', 'UserInstitution'], 'Unknown'))}</div>
                                                                                                                                                    </div>
                                                                                                                                                </Card>
            
                                                                                                                                                <Card className="gx-online-pay-card gx-py-0" style={{ marginBottom: '16px', background: '#fff', border: '1px solid #eeee' }}>
                                                                                                                                                    <span className="gx-d-block gx-font-weight-medium gx-mb-2" style={{ color: '#8C8C8C' }}><IntlMessages id="Basic-Info"/></span>
            
                                                                                                                                                    <Input placeholder="John Doe" value={fullName} onChange={(e) => setFullName(e.target.value)} />
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-2"><IntlMessages id="Formal-Picture"/></span>
                                                                                                                                                    <ImgCrop rotate fillColor={'rgba(0,0,0,0)'}>
                                                                                                                                                        <Upload
                                                                                                                                                            className="gx-online-admission-upload"
                                                                                                                                                            style={{ width: '100%!important' }}
                                                                                                                                                            name="StudentPicture"
                                                                                                                                                            maxCount={1}
                                                                                                                                                            action={(file) => console.log(file)}
                                                                                                                                                            showUploadList={false}
                                                                                                                                                            customRequest={(x) => {
                                                                                                                                                                setStudentPic(x.file);
                                                                                                                                                                setUploadPicText(x.file.name);
                                                                                                                                                            }}
                                                                                                                                                            listType="picture"
                                                                                                                                                            beforeUpload={(file) => {
                                                                                                                                                                const isPNG = (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg');
                
                                                                                                                                                                if (!isPNG) {
                                                                                                                                                                    message.error(`${file.name} is not a png, jpg or jpeg file`);
                                                                                                                                                                }
                
                                                                                                                                                                return isPNG || Upload.LIST_IGNORE;
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <Button className="gx-w-100 gx-d-block" icon={<UploadOutlined />}>{uploadPicText ? uploadPicText : <IntlMessages id="Upload-Picture"/>}
                                                                                                                                                                {
                                                                                                                                                                    studentPic ?
                                                                                                                                                                        <span style={{ display: 'block', position: 'absolute', right: '10px', top: '0px' }}>
                                                                                                                                                                            <DeleteOutlined
                                                                                                                                                                                onClick={(e) => {
                                                                                                                                                                                    e.stopPropagation()
                                                                                                                                                                                    setStudentPic(undefined);
                                                                                                                                                                                    setUploadPicText('Click to upload');
                                                                                                                                                                                }
                                                                                                                                                                                }
                                                                                                                                                                            />
                                                                                                                                                                        </span>
                                                                                                                                                                        : <></>
                                                                                                                                                                }
                
                                                                                                                                                            </Button>
                
                                                                                                                                                        </Upload>
                                                                                                                                                    </ImgCrop>
            
                                                                                                                                                </Card>
            
                                                                                                                                                <Card className="gx-online-pay-card gx-py-0" style={{ marginBottom: '16px', background: '#fff', border: '1px solid #eeee' }}>
                                                                                                                                                    <span className="gx-d-block gx-font-weight-medium gx-mb-2" style={{ color: '#8C8C8C' }}><IntlMessages id="Details"/></span>
                                                                                                                                                    <TextArea rows={4} value={classroomText} placeholder="Class 10 - Science - Section A" />
            
                                                                                                                                                </Card>
            
                                                                                                                                                <Card className="gx-online-pay-card gx-py-0" style={{ marginBottom: '16px', background: '#fff', border: '1px solid #eeee' }}>
                                                                                                                                                    <span className="gx-d-block gx-font-weight-medium gx-mb-2" style={{ color: '#8C8C8C' }}><IntlMessages id="sidebar.Contact"/></span>
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-2"><IntlMessages id="Student-Phone"/></span>
                                                                                                                                                    <Input prefix="+88" placeholder="01XXX XXXXXX" maxLength={15} minLength={11} value={phone} onChange={(e) => handlePhoneInputChange(e, 'studentPhone')} />
                                                                                                                                                    {studentPhoneError && <div style={{ marginTop: '10px', color: 'red' }}>{<IntlMessages id="Phone-Error"/>}</div>}
            
                                                                                                                                                    {/* <span className="gx-d-block gx-mb-2 gx-mt-2">Student Email</span>
                                                                                                                                                        <Input placeholder="johndoe@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                                                                                                                        {emailError && <div style={{ marginTop: '10px', color: 'red' }}>{emailError}</div>} */}
            
                                                                                                                                                </Card>
            
                                                                                                                                                <Card className="gx-online-pay-card gx-py-0" style={{ marginBottom: '16px', background: '#fff', border: '1px solid #eeee' }}>
                                                                                                                                                    <span className="gx-d-block gx-font-weight-medium gx-mb-2" style={{ color: '#8C8C8C' }}><IntlMessages id="Parent-Contact"/></span>
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-2"><IntlMessages id="Parent1Phone"/></span>
                                                                                                                                                    <Input prefix="+88" placeholder="01XXX XXXXXX" maxLength={15} minLength={11} value={parent1Phone} onChange={(e) => handlePhoneInputChange(e, 'parent1Phone')} />
                                                                                                                                                    {parent1PhoneError && <div style={{ marginTop: '10px', color: 'red' }}>{<IntlMessages id="Phone-Error"/>}</div>}
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-2"><IntlMessages id="Parent2Phone"/></span>
                                                                                                                                                    <Input prefix="+88" placeholder="01XXX XXXXXX" maxLength={15} minLength={11} value={parent2Phone} onChange={(e) => handlePhoneInputChange(e, 'parent2Phone')} />
                                                                                                                                                    {parent2PhoneError && <div style={{ marginTop: '10px', color: 'red' }}>{<IntlMessages id="Phone-Error"/>}</div>}
            
                                                                                                                                                </Card>
            
            
                                                                                                                                                <Card className="gx-online-pay-card gx-py-0 gx-mb-3" style={{marginBottom: '8px', background: '#fff', border: '1px solid #eeee' }}>
                                                                                                                                                    <span className="gx-d-block gx-font-weight-medium gx-mb-2" style={{ color: '#8C8C8C' }}><IntlMessages id="Additional-Info"/></span>
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2"><IntlMessages id="Address"/></span>
                                                                                                                                                    <Input placeholder={languageDefault === 'English' ? 'Enter your address': 'আপনার ঠিকানা লিখুন'} value={address} onChange={(e) => setAddress(e.target.value)} />
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Date-Of-Birth"/></span>
                                                                                                                                                    <DatePicker
                                                                                                                                                        style={{ width: '100%' }}
                                                                                                                                                        format={'DD-MM-YYYY'}
                                                                                                                                                        value={moment(dateOfBirth, 'DD-MM-YYYY')}
                                                                                                                                                        onChange={(v) => setDateOfBirth(moment(v).format('DD-MM-YYYY'))}
            
                                                                                                                                                    />
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Blood-Group"/></span>
                                                                                                                                                    <Select style={{ width: '100%' }} value={bloodGroup} onChange={(v) => setBloodGroup(v)}>
                                                                                                                                                        <Select.Option value="A+">A+</Select.Option>
                                                                                                                                                        <Select.Option value="A-">A-</Select.Option>
                                                                                                                                                        <Select.Option value="B+">B+</Select.Option>
                                                                                                                                                        <Select.Option value="B-">B-</Select.Option>
                                                                                                                                                        <Select.Option value="AB+">AB+</Select.Option>
                                                                                                                                                        <Select.Option value="AB-">AB-</Select.Option>
                                                                                                                                                        <Select.Option value="O+">O+</Select.Option>
                                                                                                                                                        <Select.Option value="O-">O-</Select.Option>
                                                                                                                                                    </Select>
            
                                                                                                                                                    {/* <span className="gx-d-block gx-mb-2 gx-mt-3">Birth Certificate Number</span>
                                                                                                                                                        <Input placeholder="15XX XXXX 45XX 8XX4X" value={birthRegistrationNumber} onChange={(e) => setBirthRegistrationNumber(e.target.value)} /> */}
            
                                                                                                                                                    <span className="gx-d-block gx-mb-2 gx-mt-3"><IntlMessages id="Facebook-Profile"/></span>
                                                                                                                                                    <Input placeholder="www.facebook.com/shaker.hossain87" value={facebookProfile} onChange={(e) => setFacebookProfile(e.target.value)} />
            
                                                                                                                                                </Card>
                                                                                                                                            </div>
            
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <></>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }
            
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        // }
                                                                        // </>
                                                                    }
                                                                </>
                                                        }
            
                                                    </>
                                                )

                                        }
                                    </>

                                }
                            </div>

                            {/* 3rd div (Fixed at Bottom) */}
                            <div style={{ position: 'sticky', bottom: 0, zIndex: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0px 4px 0px 0px #f7f7f7', background: '#f5f5f5', padding: '16px' }}>
                                {stepCount === 7 ? (
                                    <div className="gx-next-btn-wrapper" style={{ width: '100%' }}>
                                        <Button loading={submitLoading} onClick={(e) => { e.stopPropagation(); handleSubmitApplication(); }} className='gx-btn-primary gx-w-100 gx-mb-2 gx-d-block'><IntlMessages id="Submit"/></Button>
                                        <Button type="default" className='gx-w-100 gx-d-block' onClick={() => { setStepCount(prev => prev - 1); setProgressPercent(prev => prev - 100 / totalStep.current); }}><IntlMessages id="GoBackEdit" /></Button>
                                    </div>
                                ) : (
                                    <>
                                        { 
                                           isSubmitSuccess === true ? 
                                           (
                                            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
                                                <div className="gx-next-btn-wrapper" style={{ width: '100%' }}>
                                                    <Button className='gx-btn-primary gx-px-5 gx-mb-2 gx-w-100 gx-d-block' onClick={() => {
                                                        setIsSubmitSuccess(false)
                                                        setSubmittedApplicationKey('')
                                                        setIsStartToFill(false)
                                                    }}><IntlMessages id="Exit"/></Button>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                                                    <p className="gx-mx-0 gx-text-grey" style={{ margin: '5px 0 0 0', paddingRight: '5px' }}>
                                                        <IntlMessages id="powered-by" />
                                                    </p>
                                                    <Image width={100} src={"/assets/images/logo-white.png"} preview={false} />
                                                </div>
                                            </div>
                                           )
                                           :

                                            isStartToFill === false ? (
                                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
                                                    <div className="gx-next-btn-wrapper" style={{ width: '100%' }}>
                                                        <Button className='gx-btn-next gx-px-5 gx-mb-2 gx-w-100 gx-d-block' onClick={() => changePageToAhead({ page: '1' })}><IntlMessages id="Lets-Go"/>!</Button>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                                                        <p className="gx-mx-0 gx-text-grey" style={{ margin: '5px 0 0 0', paddingRight: '5px' }}>
                                                            <IntlMessages id="powered-by" />
                                                        </p>
                                                        <Image width={100} src={"/assets/images/logo-white.png"} preview={false} />
                                                    </div>
                                                </div>


                                            ):(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                                                <p className="gx-mx-0 gx-text-grey" style={{ margin: '5px 0 0 0', paddingRight: '5px' }}>
                                                    <IntlMessages id="powered-by" />
                                                </p>
                                                <Image width={100} src={"/assets/images/logo-white.png"} preview={false} />
                                            </div>)
                                        }
                                    </>
                                     
                                   
                                )}
                            </div>
                        </Col>
                    }
                </>
            }
        </Row>
    );
};

export default OnlineAdmissionStudent;
