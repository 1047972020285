import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
} from "../../firebase/firebase";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  SIGNIN_PHONE_USER,
  SIGNIN_PHONE_USER_SMS_CODE,
  ADD_PROGRESS_OBJECT,
  UPDATE_PROGRESS_OBJECT,
  Remove_All_PROGRESS_OBJECT,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  userPhoneSignInSuccess,
  userPhoneSignInSMSCode,

} from "../../appRedux/actions/Auth";


import Mixpanel from "../../api/Mixpanel";
import axios from "axios";
import { set } from "lodash-es";
import CloudDatabase from "../../api/CloudDatabase";
import Discord from "../../api/Discord";

const createUserWithEmailPasswordRequest = async (
  email,
  password,
  username,
  accountType,
  referralID,
  isSignUpZoom,
  isBusinessAccount, 
  phone, institutionname
) => {

    console.log('Creating new User: ', {
        email,
        password,
        username,
        accountType,
        referralID,
        isSignUpZoom,
        isBusinessAccount, 
        phone, institutionname
    });

    return await auth.createUserWithEmailAndPassword(email, password).then(async (authUser) => {

                        console.log('Creating new User: ', {
                            email,
                            password,
                            username,
                            accountType,
                            referralID,
                            isSignUpZoom,
                            isBusinessAccount, 
                            phone, institutionname
                        })

                    Mixpanel.init();
                    Mixpanel.record({eventName: 'NEW-USER-SIGNUP-REACT-WEB', eventProperties: {
                        email,
                        username,
                        accountType,
                        referralID: referralID ? referralID : 'NOT REFERRED BY ANYONE'
                    }});

                    if (accountType === 'Teacher'){

                        await CloudDatabase.createNewTeacherDatabase({
                            uid: authUser.user.uid,
                            email,
                            name: username,
                            type: "EdutechsSignUp-ReactWeb",
                            referralID: referralID,
                            isSignUpZoom : isSignUpZoom,
                            isBusinessAccount, phone, institutionname
                        });
                    }
                    else if (accountType === 'Admin'){
                        await CloudDatabase.createNewAdminDatabase({
                            uid: authUser.user.uid,
                            email,
                            name: username,
                            type: "EdutechsSignUp-ReactWeb",
                            referralID: referralID,
                            isSignUpZoom : isSignUpZoom, phone, institutionname
                        });
                    }

                    Discord.logNewSignUpToDiscord({
                        userName:username, accountType, userEmail:email, institutionName:institutionname, phone
                    })

                    return authUser;
                })
                .catch((error) => error);
}
    

const signInUserWithEmailPasswordRequest = async (email, password, TrueByPass, uid, AUTO_redirectToStudentDashboard) =>
  
    await auth
    .signInWithEmailAndPassword(TrueByPass === true ? 'edutechs@gmail.com' : email, TrueByPass === true ? 'edutechs321' : password)
    .then(async (authUser) => {

        if (TrueByPass === true){

            if (uid){
                //this means no email was passed in but a UID so log in the user with this uid instead
                //is primarily now used for student-auto-login
                const newAuthuser = JSON.parse(JSON.stringify(authUser));
                set(newAuthuser, ['user', 'uid'], uid);
                return newAuthuser
            }

            //this is primary used for devlogin
            let  p = await axios.post('https://edtch.pw/api-get-uid-from-email/', {
                email: email
            });
            console.log('True-ByPass Response: ', p);
            const newAuthuser = JSON.parse(JSON.stringify(authUser));
            set(newAuthuser, ['user', 'uid'], p.data.uid);
            if (AUTO_redirectToStudentDashboard){
                set(newAuthuser, ['AUTO_redirectToStudentDashboard'], uid);
            }
            return newAuthuser
        }

        return authUser;
    })
    .catch((error) => error);

const signInUserWithPhoneRequest = async (appVerifier, phone, accountType) =>
  await auth
    .signInWithPhoneNumber(phone, appVerifier)
    .then(async (confirmation) => {
      return confirmation;
    })
    .catch((error) => error);

const signInUserWithPhoneRequest_ConfirmSMSCode = async (
  phone,
  accountType,
  confirmation,
  code
) =>
  await confirmation
    .confirm(code)
    .then(async (authUser) => {
      return authUser;
    })
    .catch((error) => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);


//1st part of processBar saga

const addObjectToProgress = (dataObj) => {
  return dataObj;
};

const updateProgressObj_ = (dataObj) => {
  return dataObj;
};

const removeObjectFromProgress = (dataObj) => {
  return dataObj;
};

//ends here


function* createUserWithEmailPassword({ payload }) {
    console.log('PAYLOAD IN STAR SAGAS AUTH FN: ', payload);
    const { email, password, username, accountType, referralID, isSignUpZoom, isBusinessAccount, phone, institutionname } = payload;
    try {
        const signUpUser = yield call(
            createUserWithEmailPasswordRequest,
            email,
            password,
            username,
            accountType,
            referralID,
            isSignUpZoom,
            isBusinessAccount, phone, institutionname
        );

        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem("user_id", signUpUser.user.uid);
            yield put(userSignUpSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        console.log(error);
        yield put(showAuthMessage(error));
    }
}

// function* signInUserWithGoogle({ payload }) {
//   const { fromScreen, accountType } = payload;
//   try {
//     const signUpUser = yield call(
//       signInUserWithGoogleRequest,
//       fromScreen,
//       accountType
//     );
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem("user_id", signUpUser.user.uid);
//       yield put(userGoogleSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

function* signInUserWithPhone({ payload }) {
  const { appVerifier, phone, accountType } = payload;
  try {
    const confirmation = yield call(
      signInUserWithPhoneRequest,
      appVerifier,
      phone,
      accountType
    );
    if (confirmation) {
      yield put(userPhoneSignInSMSCode({ confirmation, phone, accountType }));
    } else {
      yield put(
        showAuthMessage("Failed to get proper login confirmation auth")
      );
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithPhoneRequest_ConfirmSMSCode_({ payload }) {
  const { phone, accountType, confirmation, code } = payload;
  try {
    const signUpUser = yield call(
      signInUserWithPhoneRequest_ConfirmSMSCode,
      phone,
      accountType,
      confirmation,
      code
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userPhoneSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// function* signInUserWithFacebook() {
//   try {
//     const signUpUser = yield call(signInUserWithFacebookRequest);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem("user_id", signUpUser.user.uid);
//       yield put(userFacebookSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

// function* signInUserWithGithub() {
//   try {
//     const signUpUser = yield call(signInUserWithGithubRequest);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem("user_id", signUpUser.user.uid);
//       yield put(userGithubSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

// function* signInUserWithTwitter() {
//   try {
//     const signUpUser = yield call(signInUserWithTwitterRequest);
//     if (signUpUser.message) {
//       if (signUpUser.message.length > 100) {
//         yield put(showAuthMessage("Your request has been canceled."));
//       } else {
//         yield put(showAuthMessage(signUpUser.message));
//       }
//     } else {
//       localStorage.setItem("user_id", signUpUser.user.uid);
//       yield put(userTwitterSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

function* signInUserWithEmailPassword({ payload }) {

    const { email, password, TrueByPass, uid, AUTO_redirectToStudentDashboard } = payload;

    try {
        const signInUser = yield call(
            signInUserWithEmailPasswordRequest,
            email,
            password,
            TrueByPass,
            uid,
            AUTO_redirectToStudentDashboard
        );
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem("user_id", signInUser.user.uid);
            yield put(userSignInSuccess(signInUser.user.uid));
            if (AUTO_redirectToStudentDashboard){
                window.location.href = `${window.location.origin}/student/dashboard?suid=${uid}`;
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}



function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
//2nd part of processBar saga
function* async_addObjectToProgressSAGA(payload) {
  const newDataObjToAdd = payload;

  try {
    yield call(addObjectToProgress, newDataObjToAdd);
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* async_updateProgressObjSAGA(payload) {
  const newDataObjToAdd = payload;

  try {
    yield call(updateProgressObj_, newDataObjToAdd);
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* async_removeObjectFromProgressSAGA(payload) {
  const newDataObjToAdd = payload;

  try {
    yield call(removeObjectFromProgress, newDataObjToAdd);
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

//End of 2nd part of processBar saga


export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

// export function* signInWithGoogle() {
//   yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
// }

export function* signInWithPhoneSMSCode() {
  yield takeEvery(
    SIGNIN_PHONE_USER_SMS_CODE,
    signInUserWithPhoneRequest_ConfirmSMSCode_
  );
}

export function* signInWithPhone() {
  yield takeEvery(SIGNIN_PHONE_USER, signInUserWithPhone);
}

// export function* signInWithFacebook() {
//   yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
// }

// export function* signInWithTwitter() {
//   yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
// }

// export function* signInWithGithub() {
//   yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
// }

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

//3rd part of processBar saga
export function* watchAddProgressObject() {
  yield takeEvery(ADD_PROGRESS_OBJECT, async_addObjectToProgressSAGA);
}

export function* watchUpdateProgressObject() {
  yield takeEvery(UPDATE_PROGRESS_OBJECT, async_updateProgressObjSAGA);
}

export function* watchRemoveProgressObject() {
  yield takeEvery(
    Remove_All_PROGRESS_OBJECT,
    async_removeObjectFromProgressSAGA
  );
}

//End of 3rd part of processBar saga


export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signInWithPhone),
    fork(signInWithPhoneSMSCode),
    fork(createUserAccount),
    // fork(signInWithGoogle),
    // fork(signInWithFacebook),
    // fork(signInWithTwitter),
    // fork(signInWithGithub),
    fork(signOutUser),
    //ProcessBar saga forking
    fork(watchAddProgressObject),
    fork(watchUpdateProgressObject),
    fork(watchRemoveProgressObject),
 
  ]);
}
