import { 
  CANCEL_ON_LOADING_FILE,
  CANCEL_UPLOADING_FILE, 
  CLOSE_PROGRESS_BAR, 
  ERROR_UPLOADING_FILE, 
  REMOVE_ERROR_FILE, 
  REMOVE_UPLOAD_DONE_FILE, 
  SHOW_PROGRESS_UPLOADING_FILE, 
  SUCCESS_UPLOADING_FILE, UPLOAD_NEW_FILE,
  GET_ONLINE_ADMISSION_DATA, 
  SET_ONLINE_APPLICATION_INDEX} from "../../constants/ActionTypes";


const INIT_STATE = {

  uploadedFiles: [],
  onlineAdmissionApplication: {},
  onlineApplicationIndex: undefined,
};

const LocalComponentsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    

    // UPLOAD FILE REDUCER CASE START
    case UPLOAD_NEW_FILE: {

      let currentUploadedFiles  = [...state.uploadedFiles];
      let existFileIndex        = currentUploadedFiles.findIndex(existFile => existFile['uid'] === action.payload.fileUID);
      let findExistingFile      = currentUploadedFiles.find(existFile => existFile['uid'] === action.payload.fileUID)
    
      if(findExistingFile){
        findExistingFile.uploadStatus         = "onLoading";
        findExistingFile.key                  = action.payload.key;
        currentUploadedFiles[existFileIndex]  = findExistingFile;

      }else{
        currentUploadedFiles.push(action.payload.file);
      }

      return {
        ...state,
        uploadedFiles: currentUploadedFiles,
      };
    }
    case SHOW_PROGRESS_UPLOADING_FILE: {

      let currentUploadedFiles  = [...state.uploadedFiles];
      const fileIndex           = currentUploadedFiles.findIndex(item => item['uid'] === action.payload.fileUID);
      const fileValue           = currentUploadedFiles.find(item => item['uid'] === action.payload.fileUID);

     
      fileValue.progress              = action.payload.percentage;
      fileValue.uploadStatus          = 'onProgress';
      currentUploadedFiles[fileIndex] = fileValue;
    


      return {
        ...state,
        uploadedFiles: currentUploadedFiles,
      };
    }

    case SUCCESS_UPLOADING_FILE: {
      let currentUploadedFiles  = [...state.uploadedFiles];
      const fileIndex           = currentUploadedFiles.findIndex(item => item['uid'] === action.payload.fileUID);
      const fileValue           = currentUploadedFiles.find(item => item['uid'] === action.payload.fileUID);

      
      fileValue.uploadStatus          = 'uploadDone';
      currentUploadedFiles[fileIndex] = fileValue;
    

      return {
        ...state,
        uploadedFiles: currentUploadedFiles

      };
    }
 
    case CANCEL_UPLOADING_FILE: {

      console.log('action.payload', action.payload)
      let currentUploadedFiles  = [...state.uploadedFiles];
      const fileIndex           = currentUploadedFiles.findIndex(item => item['uid'] === action.payload.fileUID);
      const fileValue           = currentUploadedFiles.find(item => item['uid'] === action.payload.fileUID);

     
      fileValue.uploadStatus          = 'cancel';
      fileValue.progress              = 0;
      currentUploadedFiles[fileIndex] = fileValue;
   

      return {
        ...state,
        uploadedFiles: currentUploadedFiles

      };
    }
    case ERROR_UPLOADING_FILE: {
    
      let currentUploadedFiles  = [...state.uploadedFiles];
      const fileIndex           = currentUploadedFiles.findIndex(item => item['uid'] === action.payload.fileUID);
      const fileValue           = currentUploadedFiles.find(item => item['uid'] === action.payload.fileUID);

 
      fileValue.uploadStatus          = 'error';
      fileValue.progress              = 0;
      currentUploadedFiles[fileIndex] = fileValue;
    

      return {
        ...state,
        uploadedFiles: currentUploadedFiles

      };
    }
    case REMOVE_ERROR_FILE: {
      let currentUploadedFiles    = [...state.uploadedFiles];
      let newUploadedVideosFiles  = currentUploadedFiles.filter(item => item['uid'] !== action.payload.fileUID);

      return {
        ...state,
        uploadedFiles: newUploadedVideosFiles

      };
    }
    case CANCEL_ON_LOADING_FILE: {
      let currentUploadedFiles    = [...state.uploadedFiles];
      const fileIndex           = currentUploadedFiles.findIndex(item => item['uid'] === action.payload.fileUID);
      const fileValue           = currentUploadedFiles.find(item => item['uid'] === action.payload.fileUID);
      fileValue.uploadStatus          = 'cancel';
      fileValue.progress              = 0;
      currentUploadedFiles[fileIndex] = fileValue;

      return {
        ...state,
        uploadedFiles: currentUploadedFiles

      };
    }
    case REMOVE_UPLOAD_DONE_FILE: {
      let currentUploadedFiles    = [...state.uploadedFiles];
      let newUploadedVideosFiles  = currentUploadedFiles.filter(item => item['uid'] !== action.payload.fileUID);

      return {
        ...state,
        uploadedFiles: newUploadedVideosFiles

      };
    }
    case CLOSE_PROGRESS_BAR: {
        let currentUploadedFiles  = [...state.uploadedFiles];
        currentUploadedFiles      = action.payload
  
        return {
          ...state,
          uploadedFiles: currentUploadedFiles
  
        };
      }
      case GET_ONLINE_ADMISSION_DATA: {
        let currentChange  ={...state.onlineAdmissionApplication};
        currentChange      = {...currentChange, ...action.payload}
        
        return {
          ...state,
          onlineAdmissionApplication: currentChange
  
        };
      }
      case SET_ONLINE_APPLICATION_INDEX: {

        return {
          ...state,
          onlineApplicationIndex: action.payload
  
        };
      }

    // UPLOAD FILE REDUCER CASE END


    default:
      return state;
  }
};

export default LocalComponentsReducer;