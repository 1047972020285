import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import LocalComponentsReducer from './LocalComponents';
import StudentProfileReducer from './StudentProfile';
import sidebarReducer from './SidebarActions';


export const createRootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    uploadFile: LocalComponentsReducer,
    profileInfo: StudentProfileReducer,
    sidebar: sidebarReducer,
});


export type AppState = ReturnType<typeof createRootReducer>;

