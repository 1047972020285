import antdEN from "antd/lib/locale-provider/bn_BD";
import bnMessages from "../locales/bn_BD.json";

const bnLang = {
  messages: {
    ...bnMessages
  },
  antd: antdEN,
  locale: 'bn-BD',
};
export default bnLang;
