import React, { useState } from 'react';
import WelcomeHeader from '../WelcomeHeader';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Card, Col, Form, message, Row } from 'antd';
import Footer from '../../../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactPage = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        setLoading(true)
        const data = {
            recepient: "ekram@edutechs.app",
            subject: `Mail from the Contact Us section of Edutechs landing page`,
            customHTML: `

            <!DOCTYPE html>
                <html>
                    <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    </head>
                    <body>
                        <p>${values.message}</p>
                        <h3>User mail ${values.email}</h3>
                    </body>
                </html>
            `
        }
        fetch('https://edtch.pw/send-email-to-anyone', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
            .then(res => {
                form.resetFields();
                message.success("Thank you for contacting us.");
                setLoading(false)
            })
            .catch(error => {
                message.error("Something went wrong! Please Try again!");
                setLoading(false)
            }
            );
    };


    const onFinishFailed = error => { console.log(error) };


    return (
        <div className="gx-welcome-pagev2">
            <WelcomeHeader />

            <main>
                <section className="gx-contact-section gx-section-padding">
                    <div className="gx-landing-container">
                        <Row className="gx-contact-content-row" justify="center" align="middle">
                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <Card className="gx-contact-card" bordered={false}>
                                    <Row justify="center" align="middle">
                                        <Col span={24} >
                                            <h3>Contact us for any of your queries</h3>
                                        </Col>
                                        <Col span={24}>
                                            <div className="gx-contact-content-info">
                                                <FontAwesomeIcon icon={faEnvelope} />
                                                <p>ekram@edutechs.app</p>
                                            </div>
                                            <div className="gx-contact-content-info">
                                                <FontAwesomeIcon icon={faPhone} />
                                                <p>+880 17007 66174</p>
                                            </div>
                                            <div className="gx-contact-content-info">
                                                <FontAwesomeIcon icon={faLocationDot} />
                                                <p>Concord Royal Court 3rd Floor, Road 27 (old), Dhanmondi, Dhaka-1209</p>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <iframe
                                                className="gx-contact-content-iframe"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.8300732733983!2d90.36755061183604!3d23.753438288568983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf518757b90f%3A0x51753b69925e6300!2sConcord%20Royal%20Court!5e0!3m2!1sen!2sbd!4v1733216704803!5m2!1sen!2sbd"
                                                title="description"
                                                loading="lazy"
                                            ></iframe>
                                        </Col>
                                        <Col span={24}>
                                            <div className="gx-line-container gx-contact-line">or directly send us a message</div>
                                        </Col>
                                        <Col span={24}>
                                            <Form
                                                form={form}
                                                onFinish={onFinish}
                                                onFinishFailed={onFinishFailed}
                                                className="gx-contact-message"
                                            >
                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your email or phone!',
                                                        },
                                                    ]}
                                                    name="email"
                                                    tooltip={"Email or phone is required!"}
                                                    required
                                                >
                                                    <TextArea placeholder="Email / Phone" autoSize />
                                                </Form.Item>

                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your message!',
                                                        },
                                                    ]}
                                                    name="message"
                                                    tooltip={"Message is required!"}
                                                    required
                                                >
                                                    <TextArea
                                                        placeholder="Message"
                                                        autoSize={{
                                                            minRows: 3,
                                                            maxRows: 5,
                                                        }}
                                                    />
                                                </Form.Item>

                                                <div>
                                                    <Button
                                                        htmlType="submit"
                                                        className="gx-btn-primary gx-contact-message-btn"
                                                        loading={loading}
                                                    >
                                                        Send
                                                    </Button>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>
            </main>

            <Footer
                heading="Ready to Elevate Your Institution?"
                buttonText="Start For Free"
                footerTop={false}
            />
        </div>
    );
};

export default ContactPage;