import { useState, useEffect, useCallback } from "react";
import { Button, Select, Menu, Dropdown, Tooltip} from "antd";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { decodeString } from "../../util/misc";
import {  DownloadOutlined } from "@ant-design/icons";
import moment from 'moment'

const { Option } = Select;

const ExportButton = ({docExtraData, tableOneTitle, tableTwoTitle, extraTable, exportType, headers, includeProps, icon, docTimestamp, docFileName, docData, containerProps,size,docOrientation, columnWidthStyle, disabled = false}) => {
    
    const { t }                     =   useTranslation();
    const [menuItems, setMenuItems] =   useState([]);

    const dataProcessing = useCallback(() => {

        let finalData = [];
        
        let tableData                = [];
        const defaultIncludeProps   = ['key'];
        const innerCompIncludeProps = includeProps? includeProps : defaultIncludeProps;
        tableData = docData?.map((item) => {
            if (item.props?.infoProp) {
                return item.props?.infoProp;
            }
            else if (typeof item === 'object') {
                return item;
            }
            else {
                return item;
            }
        });
        // for decode Strings         
        for (let key in tableData) {
            let obj = tableData[key];
            for (let objKey in obj) {

                if (obj.hasOwnProperty(objKey)) {
                    const value = obj[objKey];
                    if (typeof value === 'string') {
                        let decodedValue = decodeString(value);
                        if((Object.is(decodedValue, -0) || decodedValue === '-0')){
                            decodedValue = 'Absent'
                        }
                        obj[objKey] = decodedValue;
                    }
                }

            }

        }
        // If tableData is not Array of Array type then convert it to an Array of Array
        if (!Array.isArray(tableData[0])) {
            const transformedData = Object.entries(tableData).map(([key, value]) => {
                const filteredItem = Object.fromEntries(
                    Object.entries(value).filter(([key]) => innerCompIncludeProps.includes(key))
                );
                return Object.values(filteredItem);
            });

            const header = Object.keys(tableData[0]).filter((key) => innerCompIncludeProps.includes(key));
            transformedData.unshift(header);

            
            finalData = transformedData;
        }
        else{
            finalData = tableData;
        }
        return { finalData, tableData };
 

    },[docData, includeProps]);
    
    const decidingFunction = useCallback((value) => {
        const { finalData, tableData } = dataProcessing();
      
        const processData = headers? tableData : finalData; 
        
        if(value    === 'PDF'){
            //intialize jsPDf 
            const unit          = "pt";
            const size          = headers?.length > 14? "A3" : "A4"; // Use A1, A2, A3 or A4
            const orientation   = docOrientation? docOrientation : headers?.length > 9? "landscape" : "portrait"; // portrait or landscape
        
            var doc             = new jsPDF(orientation, unit, size);

            let filename;
            if(docFileName && docFileName !== ""){
                filename = `${docFileName}.pdf`; 
            }
            else{
                filename = `Table-Pdf-${(new Date()).getTime()}.pdf`;
            }
           
            docFileName? doc.text(docFileName,40,30) : doc.text(filename,40,30);
            
            let y = 60;
            let ySum = y;
            docExtraData?.forEach((val) => {
                doc.setFontSize(12);
                doc.text(val.title + ': ' + val.data, 40, ySum);
                ySum= ySum + 15;
            })

            if(tableOneTitle){
                doc.text(tableOneTitle, 40, ySum+20);
            }
            headers?
            doc.autoTable(headers, processData, {
                styles: {
                    overflow: 'linebreak',
                    columnWidth: 'wrap'
                },
                columnStyles: columnWidthStyle? columnWidthStyle :
                {
                    0: { columnWidth: 'auto' }
                },
                startY: ySum+30,
            }) // Design as a table takes header and data props,
            :
            doc.autoTable({
                styles: {
                    overflow: 'linebreak',
                    columnWidth: 'wrap'
                },
                columnStyles: columnWidthStyle? columnWidthStyle :
                {
                    0: { columnWidth: 'auto' }
                },
                head: [processData[0].map(header => header.charAt(0).toUpperCase() + header.slice(1))],
                body: processData.slice(1)
            });

            if(extraTable && extraTable?.length !== 0){
                let xPos= 40;
                let yPos= 40;

                // for decode Strings         
                for (let key in extraTable) {
                    let obj = extraTable[key];

                    for (let objKey in obj) {

                        if (obj.hasOwnProperty(objKey)) {
                            const value = obj[objKey];
                            if (typeof value === 'string') {
                                let decodedValue = decodeString(value);
                                if(Object.is(decodedValue, -0) || decodedValue === '-0'){
                                    decodedValue = 'Absent'
                                }
                          
                                obj[objKey] = decodedValue;
                            }
                        }

                    }

                }

                doc.addPage();
                if(tableTwoTitle){
                    doc.text(tableTwoTitle, xPos, yPos);
                }
                doc.autoTable(headers, extraTable, {
                    styles: {
                        overflow: 'linebreak',
                        columnWidth: 'wrap'
                    },
                    columnStyles: columnWidthStyle? columnWidthStyle :
                    {
                        0: { columnWidth: 'auto' }
                    },
                    startY: yPos+10,
                }) // Design as a table takes header and data props,
            
            }


            doc.save(filename);
            return false;
        } // only for export pdf

        else if(value === 'XLSX'){
            let filename;

            if(docFileName && docFileName !== ""){
                filename = `${docFileName}`;
            }else{
                filename = `Table-Excel-${(new Date()).getTime()}`;
            }
            let createXLSLFormatObj  = [];
            let colWidthStyle        = [];
            if(headers){
                const excelHeaders = headers.map(item => item.header);
                
                let xlsxHeader           = excelHeaders; // for header of excel

                createXLSLFormatObj.push(xlsxHeader);
                
                let xlsRows              = [];
                
                for (let index = 0; index < processData?.length; index++) {
                    let top = [];
                    for(let i=0;i<headers?.length; i++){
                        top.push( { t:"s", v:processData[index][headers[i]?.dataKey], z: "@" })
                        if(index   < 1){
                            //const max_width    = Math.max(26, processData[index][headers[i]?.dataKey]?.length);
                            let columnWidth    = {wch: 26}
                            colWidthStyle.push(columnWidth);
                        }
                    }
                    xlsRows.push(top);
                    
                }
            
                createXLSLFormatObj.push(...xlsRows);

            }
            else{
                createXLSLFormatObj = processData;
                
                //loop for set column width
                let data = processData.slice(1);
                
                for (let i = 0; i < data?.length; i++) {
                    let row = data[i];
                    for (let j = 0; j < row?.length; j++) {
                        let value = row[j];

                        const max_width = Math.max(26, value?.length);
                        let columnWidth = { wch: max_width };
                        colWidthStyle.push(columnWidth);

                    }
                }
                //end loop
            }
            
            let wb                  = XLSX.utils.book_new();
            let ws1                 = XLSX.utils.json_to_sheet(createXLSLFormatObj,{skipHeader: 1});
            //let colWidthDataStyle   = [ { wch: 24 }, { wch: 26 },{ wch: 26 },{ wch: 26 },{ wch: 16 },{ wch: 16 }, { wch: 26 } ];
            ws1["!cols"]            = colWidthStyle;

            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${filename}.xlsx`);

            return false;
        }   // only for export xslx


    },[columnWidthStyle, dataProcessing, docExtraData, docFileName, docOrientation, extraTable, headers, tableOneTitle, tableTwoTitle]);
    
    const handleChange = useCallback((value) => {

        decidingFunction(value);

    }, [decidingFunction]);
    
    useEffect(() => {
        let outputItems = [];
        let index = 1;
        for(let value in exportType){

            outputItems.push(
                <Menu.Item key={String(index)} type="primary">
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }} onClick={() => handleChange(exportType[value])}>

                        <span className="gx-ml-1 gx-mr-2">{exportType[value]}</span>

                    </div>
                </Menu.Item>
            );
            index = index + 1
            setMenuItems(outputItems);
        }
    }, [exportType, docExtraData, handleChange, docData])

    return(

        <div {...containerProps}>
            {icon?

                <Dropdown
                    overlay={<Menu onClick={() => { }}>
                        {menuItems}
                    </Menu>}
                >
                    <Tooltip title="Export">

                        <Button disabled={disabled} className="gx-mx-0 gx-ml-1" style={{ marginBottom: 0, height: '32px' }} type='dashed' shape="square" icon={<DownloadOutlined />}></Button>

                    </Tooltip>

                </Dropdown>

                :
                <Select
                    disabled={disabled}
                    style={{ borderRadius: '4px' }}
                    onChange={handleChange}
                    value={t("Export As")}
                    size={size ? size : 'middle'}
                >
                    {exportType?.map((item) => (
                        <Option value={item}>{t(item)}</Option>
                    ))}
                </Select>
            }
        </div>
    );

}; 

export default ExportButton;