const nameMappingForBusinessRoute = {
    "Classrooms"    : 'Products',
    "Classroom"     : 'Product',
    "classroom"     : 'product',
    "STUDENT"       : 'CLIENT',
    "student"       : 'client',
    "students"      : 'clients',
    "Student"       : 'Client',
    "Students"      : 'Clients',
    "subject"       : 'branch',
    "Subject"       : 'Branch',
    "Subjects"      : 'Branches',
    "Class Tuition" : 'Monthly Subscription',
    "class"         : 'product',
    "Curriculum"    : 'Area',
    "Curriculums"   : 'Areas',
    "curriculum"    : 'area',
    "Tuition"       : 'Subscription',
    "tuition"       : 'subscription',
    "Teacher"       : 'Manager',
    "TEACHER"       : 'MANAGER',
    "batches"       : 'products',
    "Batches"       : 'Products',
    "Batch"         : 'Product',
    "EduCRM"        :  'CRM',
    'Admission'     : 'Registration'
}

export const nameMappingForCricketRoute = {
    "Branches"      : 'Divisions',
    "Classrooms"    : 'Academies',
    "Classroom"     : 'Academy',
    "classroom"     : 'academy',
    "STUDENT"       : 'PLAYER',
    "student"       : 'player',
    "students"      : 'players',
    "Student"       : 'Player',
    "Students"      : 'Players',
    "subject"       : 'Thana',
    "Subject"       : 'Thana',
    "Subjects"      : 'Thanas',
    "Class Tuition" : 'Monthly Subscription',
    "class"         : 'school',
    "Curriculum"    : 'District',
    "Curriculums"   : 'Districts',
    "curriculum"    : 'district',
    "Tuition"       : 'Subscription',
    "tuition"       : 'subscription',
    "Teacher"       : 'Manager',
    "TEACHER"       : 'MANAGER',
    "batches"       : 'schools',
    "Batches"       : 'Schools',
    "Batch"         : 'School',
    "EduCRM"        : 'CRM',
    'Admission'     : 'Registration'
}

export default nameMappingForBusinessRoute; 