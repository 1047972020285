import React from 'react';

const FutureProofSection = () => {
  return (
    <div className="gx-future-proof-section">
      <div className="gx-future-content-wrapper">
        <div className="gx-icon">
          <img src="/assets/images/welcomev2/star4.png" alt="Icon" />
        </div>
        <h1 className="gx-title">Future-proof your school with your own personal AI</h1>
        <div className="gx-scroll-indicator gx-mt-5">
        <img
            src="/assets/images/welcomev2/explore.gif"
            alt="Icon"
            style={{ filter: 'invert(1)', width: '20%' }}
        />
        </div>
      </div>
    </div>
  );
};

export default FutureProofSection;
