import React from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const BannerSection = () => {
  return (
    <section className="gx-banner">
      <div className="gx-banner-content">
        <h1 className="gx-banner-content-heading">
          Bringing Classrooms Together{" "}
          <span className="gx-banner-content-heading_span">with Technology</span>
        </h1>
        <p className="gx-mb-5">without losing the human touch</p>

        <div className="gx-line-container">For</div>

        <div className="gx-icons-section">
          <div className="gx-icon-card">
            <img
              src="/assets/images/welcomev2/teacher_badge.png"
              alt="Teachers"
            />
            <p className="gx-mb-0">Teachers</p>
          </div>
          <div className="gx-icon-card">
            <img
              src="/assets/images/welcomev2/student_badge.png"
              alt="Students"
            />
            <p>Students</p>
          </div>
          <div className="gx-icon-card">
            <img
              src="/assets/images/welcomev2/parent_badge.png"
              alt="Parents"
            />
            <p>Parents</p>
          </div>
          <div className="gx-icon-card">
            <img
              src="/assets/images/welcomev2/school_leader_badge.png"
              alt="Admins"
            />
            <p>Admins</p>
          </div>
        </div>

        <div className="gx-ratings">
          <div className="gx-stars gx-mb-2">
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <div className="gx-half-star">
              <StarOutlined className="gx-star-outline" />
              <StarFilled className="gx-star-filled" />
            </div>
          </div>
          <p className="gx-info">
            46,000+ Users <span className="gx-center-dot"></span> 3600+ Reviews
          </p>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
