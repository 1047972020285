import { UPDATE_SIDEBAR } from "../../constants/ActionTypes";

const initialState = {
  updateSidebar: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SIDEBAR:
      return {
        ...state,
        updateSidebar: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarReducer;