import { Suspense } from "react";
import ReactDOM from "react-dom";
import { LoadingOutlined } from "@ant-design/icons";
import NextApp from './NextApp';
import * as serviceWorker from './registerServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render(<>

    <Suspense fallback={<div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    /></div>}>
        <NextApp />
    </Suspense>


</>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
