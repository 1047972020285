import React from "react";
const FeaturesSection = () => {
  const features = [
    {
      id: 1,
      imgSrc: "assets/images/welcomev2/feature1.png",
      iconSrc: "/assets/images/welcomev2/feature-icon1.png",
      title: "Manage Like a Pro!",
      description:
        "Your students, parents, staff, and moderators have their own accounts which they can use to see attendance, payments, and assessment status.",
      isEven: false,
    },
    {
      id: 2,
      imgSrc: "assets/images/welcomev2/feature2.png",
      iconSrc: "/assets/images/welcomev2/feature-icon2.png",
      title: "Automate Payments",
      description:
        "Manage fees, payments, reminders and invoices seamlessly to stay on top of all your expenses and receivables with insightful financial reports.",
      isEven: true,
    },
    {
      id: 3,
      imgSrc: "assets/images/welcomev2/feature3.png",
      iconSrc: "/assets/images/welcomev2/feature-icon3.png",
      title: "Ensure Student Safety",
      description:
        "Track student and staff check in/out, send notification SMS to parents and ensure safety for students.",
      isEven: false,
    },
    {
      id: 4,
      imgSrc: "assets/images/welcomev2/feature4.png",
      iconSrc: "/assets/images/welcomev2/feature-icon4.png",
      title: "Track Performance",
      description:
        "Get detailed progress report of your students’ performance including assessments and attendance.",
      isEven: true,
    },
    {
      id: 5,
      imgSrc: "assets/images/welcomev2/feature5.png",
      iconSrc: "/assets/images/welcomev2/feature-icon5.png",
      title: "Build the classroom of your dreams",
      description:
        "With tons of powerful first-party add-ons, you can customize your teaching experience however you like it to be.",
      isEven: false,
    },
  ];

  return (
    <section className="gx-features-section gx-section-padding">
      <div className="gx-landing-container">
        {features.map((feature) => (
          <div
            key={feature.id}
            className={`gx-feature-list-wrapper gx-feature${feature.id} gx-features-padding-bottom`}
          >
            <div
              className={`gx-feature-left gx-left-column ${
                feature.isEven ? "gx-col-order-2" : "gx-col-order-1"
              }`}
            >
              <img
                src={feature.imgSrc}
                alt={`Feature ${feature.id}`}
                className={`gx-responsive-img gx-feature-img ${feature.id === 3 ? 'gx-positive-margin' : ''}`}
                height="auto"
         
              />
            </div>
            <div
              className={`gx-feature-right gx-right-column ${
                feature.isEven ? "gx-col-order-1" : "gx-col-order-2"
              }`}
            >
              <div className={`gx-feature-info ${feature.id === 3 ? 'gx-negative-margin' : ''}`}>
                <img
                  src={feature.iconSrc}
                  alt={`Feature Icon ${feature.id}`}
                />
                <h2 className="gx-feature-title gx-mt-3">
                  {feature.title}
                </h2>
                <p className="gx-feature-description">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
