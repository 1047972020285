import { get } from "lodash-es";

export const discrepantDataToCheckArrForStudents = ['Phone', 'customID', 'parent1phone', 'parent2phone', 'RFIDCode'];



//this will check across teacher, admin, and teacher assistant accounts if there are student data which are discreapant across separate batches
export const checkIfAccountHasDiscrepantStudents = (fullDB) => {

    let discrepantUIDArr           = [];  
    let discrepantUIDToKeyMapper   = {};
    
    if (get(fullDB, ['PublicInfo', 'UserProffession'], 'Unknown') === 'Teacher'){

        let fullStudentJSON         = get(fullDB, ['VolatileLocalData', 'UniqueStudentJSONList'], {});
        let suidToDataMapper        = {};

        for (let oneSuid in fullStudentJSON) {
            let studentData                         = get(fullStudentJSON, [oneSuid], {});
            let acceptedBatchesForThisStudent       = get(studentData, ['AcceptedClasses'], {});

            for (let oneGrade in  acceptedBatchesForThisStudent) {
                for (let oneSubject in get(acceptedBatchesForThisStudent, [oneGrade], {})) {
                    for (let oneBatch in get(acceptedBatchesForThisStudent, [oneGrade, oneSubject], {})) {

                        let thisStudentBatchData = get(acceptedBatchesForThisStudent, [oneGrade, oneSubject, oneBatch], {});

                        for (let oneDiscrepantData of discrepantDataToCheckArrForStudents) {
                            if (get(thisStudentBatchData, [oneDiscrepantData], undefined) && get(suidToDataMapper, [oneSuid, oneDiscrepantData], undefined)){
                                //check to see if previous checked location and current checked location are the same
                                if (get(thisStudentBatchData, [oneDiscrepantData], undefined) !== get(suidToDataMapper, [oneSuid, oneDiscrepantData], undefined)) {
                                    discrepantUIDArr.push(oneSuid);
                                    if (discrepantUIDToKeyMapper[oneSuid]) {
                                        discrepantUIDToKeyMapper[oneSuid] += `, ${oneDiscrepantData}`;
                                    } else {
                                        discrepantUIDToKeyMapper[oneSuid] = oneDiscrepantData;
                                    }
                                }
                            }
                        }

                        suidToDataMapper[oneSuid] = thisStudentBatchData;
                    }
                }
            }
        }

        return {
            discrepantUIDArr: [...new Set(discrepantUIDArr)],
            suidToDataMapper,
            discrepantUIDToKeyMapper
        };
    }
    else if (get(fullDB, ['PublicInfo', 'UserProffession'], 'Unknown') === 'Admin'){

        let allTeachersData         = get(fullDB, ['VolatileLocalData', 'Admined'], {});
        let suidToDataMapper        = {};

        for (let oneTeacherUID in allTeachersData){

            //console.log('Working on Teacher UID: ', oneTeacherUID);

            let userClass = get(allTeachersData, [oneTeacherUID, 'UserClass'], {});

            for (let oneGrade in userClass) {
                for (let oneSubject in get(userClass, [oneGrade], {})) {
                    for (let oneBatch in get(userClass, [oneGrade, oneSubject, 'Streams'], {})) {

                        let acceptedStudentsJSON = get(userClass, [oneGrade, oneSubject, 'Streams', oneBatch, 'AcceptedStudents'], {});

                        for (let suid in acceptedStudentsJSON){

                            let thisStudentBatchData = get(acceptedStudentsJSON, [suid], {});
                            // console.log(' ');
                            // console.log('Working on SUID: ', suid, thisStudentBatchData);

                            for (let oneDiscrepantData of discrepantDataToCheckArrForStudents) {

                                //console.log(`CHECKING: ${oneDiscrepantData} - ${get(thisStudentBatchData, [oneDiscrepantData], undefined)} : ${get(suidToDataMapper, [suid, oneDiscrepantData], undefined)}`)

                                if (get(thisStudentBatchData, [oneDiscrepantData], undefined) && get(suidToDataMapper, [suid, oneDiscrepantData], undefined)){
                                    //check to see if previous checked location and current checked location are the same
                                    if (get(thisStudentBatchData, [oneDiscrepantData], undefined) !== get(suidToDataMapper, [suid, oneDiscrepantData], undefined)) {
                                        discrepantUIDArr.push(suid);
                                        if (discrepantUIDToKeyMapper[suid]) {
                                            discrepantUIDToKeyMapper[suid] += `, ${oneDiscrepantData}`;
                                        } else {
                                            discrepantUIDToKeyMapper[suid] = oneDiscrepantData;
                                        }
                                    }
                                }
                            }

                            suidToDataMapper[suid] = thisStudentBatchData;
                        }
                    }
                }
            }


        }

        //console.log('suidToDataMapper: ', suidToDataMapper);

        return {
            discrepantUIDArr: [...new Set(discrepantUIDArr)],
            suidToDataMapper,
            discrepantUIDToKeyMapper
        };

    }
    else if (get(fullDB, ['PublicInfo', 'UserProffession'], 'Unknown') === 'TeacherAssistant'){

        let allTeachersData         = get(fullDB, ['VolatileLocalData', 'Moderating'], {});
        let suidToDataMapper        = {};

        for (let oneTeacherUID in allTeachersData){

            // console.log('Working on Teacher UID: ', oneTeacherUID);

            let userClass = get(allTeachersData, [oneTeacherUID, 'UserClass'], {});

            for (let oneGrade in userClass) {
                for (let oneSubject in get(userClass, [oneGrade], {})) {
                    for (let oneBatch in get(userClass, [oneGrade, oneSubject, 'Streams'], {})) {

                        let acceptedStudentsJSON = get(userClass, [oneGrade, oneSubject, 'Streams', oneBatch, 'AcceptedStudents'], {});

                        for (let suid in acceptedStudentsJSON){

                            let thisStudentBatchData = get(acceptedStudentsJSON, [suid], {});

                            // if (thisStudentBatchData['Name'] === 'Kuddus'){
                            //     console.log(' ');
                            //     console.log('Working on SUID: ', suid, oneGrade, oneSubject, oneBatch, thisStudentBatchData);
                            // }

                            for (let oneDiscrepantData of discrepantDataToCheckArrForStudents) {

                                // if (thisStudentBatchData['Name'] === 'Kuddus'){
                                //     console.log(`CHECKING: ${oneDiscrepantData} - ${get(thisStudentBatchData, [oneDiscrepantData], undefined)} : ${get(suidToDataMapper, [suid, oneDiscrepantData], undefined)}`)
                                // }

                                if (get(thisStudentBatchData, [oneDiscrepantData], undefined) && get(suidToDataMapper, [suid, oneDiscrepantData], undefined)){
                                    //check to see if previous checked location and current checked location are the same
                                    if (get(thisStudentBatchData, [oneDiscrepantData], undefined) !== get(suidToDataMapper, [suid, oneDiscrepantData], undefined)) {
                                        // if (thisStudentBatchData['Name'] === 'Kuddus'){
                                        //     console.log('Yes it is discrepant!');
                                        // }
                                        discrepantUIDArr.push(suid);
                                        if (discrepantUIDToKeyMapper[suid]) {
                                            discrepantUIDToKeyMapper[suid] += `, ${oneDiscrepantData}`;
                                        } else {
                                            discrepantUIDToKeyMapper[suid] = oneDiscrepantData;
                                        }
                                    }
                                }
                            }

                            suidToDataMapper[suid] = thisStudentBatchData;
                        }
                    }
                }
            }


        }

        //console.log('suidToDataMapper: ', suidToDataMapper);

        return {
            discrepantUIDArr: [...new Set(discrepantUIDArr)],
            suidToDataMapper,
            discrepantUIDToKeyMapper
        };

    }
}