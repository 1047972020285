import React from 'react';

const PrivacySection = () => {
  return (
    <section className="gx-privacy-section gx-section-padding">
      <div className="gx-landing-container gx-privacy-content-wrapper">
        <div className="gx-privacy-content">
          <div className="gx-left-column gx-col-order-1">
            <img className='gx-responsive-img' height="auto" src="/assets/images/welcomev2/privacy.png" alt="Privacy" />
          </div>
          <div className="gx-right-column gx-col-order-2 ">
            <div className="gx-feature-info">
              <h2 className="gx-feature-title gx-mt-3">Privacy first—always</h2>
              <p className="gx-feature-description">
                Our users’ personal data is kept confidential and will never be shared with anyone without their explicit permission.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacySection;
