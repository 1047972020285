

import React, { useState, useEffect } from 'react';

const imageData = [
    { src: "/assets/images/welcomev2/hero/edbridge_1.jpg", alt: "Teacher 2" },
    { src: "/assets/images/welcomev2/hero/orchid_1.jpg", alt: "Teacher 9" },
    { src: "/assets/images/welcomev2/hero/refayat_sir_1.jpg", alt: "Teacher 10" },
    { src: "/assets/images/welcomev2/hero/itz_2.jpg", alt: "Teacher 4" },
    { src: "/assets/images/welcomev2/hero/mac_master_1.jpg", alt: "Teacher 5" },

    { src: "/assets/images/welcomev2/hero/annonymous_school_01.jpg", alt: "Teacher 8" },
    { src: "/assets/images/welcomev2/hero/annonymous_school_02.jpg", alt: "Teacher 1" },
    { text: 'Build a Classroom 10 years ahead of your Competition', alt: "Text" },
    { src: "/assets/images/welcomev2/hero/annonymous_school_03.jpg", alt: "Teacher 11" },
    { src: "/assets/images/welcomev2/hero/annonymous_school_05.jpg", alt: "Teacher 4" },
];

const CurveImageSection = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [imageSize, setImageSize] = useState('100%'); // Default image size
    const [textBoxSize, setTextBoxSize] = useState('100%')


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const getCurveTransformStyle = (index) => {
        // Get the screen width and height
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Default translation factors
        let translateYFactor = screenHeight * 0.015;
        let translateXFactor = screenWidth * 0.020;
        let rotateFactor = 18;

        if (screenWidth > 1400) {
            translateYFactor = screenHeight * 0.015;
            translateXFactor = screenWidth * 0.020;
            rotateFactor = 18;
        }

        rotateFactor = 0

        if (index === 0) {
            return {
                transform: `translateY(-${translateYFactor * 7.5}px) translateX(-${translateXFactor * 3}px) rotate(${rotateFactor}deg)`
            };
        }
        if (index === 1) {
            return {
                transform: `translateY(-${translateYFactor * 1.4}px) translateX(-${translateXFactor * 1.5}px) rotate(${rotateFactor * 0.4}deg)`
            };
        }
        if (index === 2) {
            return {
                transform: `translateY(0) rotate(0deg)`
            };
        }
        if (index === 3) {
            return {
                transform: `translateY(-${translateYFactor * 1.4}px) translateX(${translateXFactor * 1.5}px) rotate(-${rotateFactor * 0.4}deg)`
            };
        }
        if (index === 4) {
            return {
                transform: `translateY(-${translateYFactor * 7.5}px) translateX(${translateXFactor * 3}px) rotate(-${rotateFactor}deg)`
            };
        }

        return {};
    };

    const getCurveTransformStyleInMobile = (index, isRotate, rowNumber) => {
        // Get the screen width and height
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
    
        // Default translation factors
        let translateYFactor = screenHeight * 0.015;
        let translateXFactor = screenWidth * 0.015;
        let rotateFactor = 15;
    
        if (screenWidth > 1400) {
            translateYFactor = screenHeight * 0.008;
            translateXFactor = screenWidth * 0.008;
            rotateFactor = 8;
        }
    
        // Logic based on the row number
        if (rowNumber === 1) {
            if (index === 0) {
                return {
                    transform: `translateY(-${translateYFactor * 1.5}px) translateX(-${translateXFactor * 8}px) rotate(${isRotate ? rotateFactor : 0}deg)`
                };
            }
            if (index === 1) {
                return {
                    transform: `translateY(0) rotate(0deg)`
                };
            }
            if (index === 2) {
                return {
                    transform: `translateY(-${translateYFactor * 1.5}px) translateX(${translateXFactor * 8}px) rotate(-${isRotate ? rotateFactor : 0}deg)`
                };
            }
        }
    
        if (rowNumber === 2) {
            if (index === 0) {
                return {
                    transform: `translateY(${translateYFactor * 0.001}px) translateX(-${translateXFactor * 8}px) rotate(${isRotate ? rotateFactor : 0}deg)`
                };
            }
            if (index === 1) {
                return {
                    transform: `translateY(0) rotate(0deg)`
                };
            }
            if (index === 2) {
                return {
                    transform: `translateY(${translateYFactor * 0.001}px) translateX(${translateXFactor * 8}px) rotate(${isRotate ? rotateFactor : 0}deg)`
                };
            }
        }
    
        if (rowNumber === 3) {
            if (index === 0) {
                return {
                    transform: `translateY(${translateYFactor * 2.5}px) translateX(-${translateXFactor * 8}px) rotate(-${isRotate ? rotateFactor : 0}deg)`
                };
            }
            if (index === 1) {
                return {
                    transform: `translateY(${translateYFactor * 1.5}px) rotate(0deg)`
                };
            }
            if (index === 2) {
                return {
                    transform: `translateY(${translateYFactor * 2.5}px) translateX(${translateXFactor * 8}px) rotate(${isRotate ? rotateFactor : 0}deg)`
                };
            }
        }
    
        return {};
    };




    return (
        <section className="gx-images-section">
            {
                parseInt(windowWidth) < 1020 ?
                    <div className="gx-images-flex-container">
                        <div className="gx-mobile-image-row">
                            {imageData.slice(0, 3).map((image, index) => (
                                <div
                                    key={index}
                                    className={`gx-image-wrapper gx-image-${index + 1}`}
                                    style={{
                                        ...getCurveTransformStyleInMobile(index, true, 1),
                                        transition: 'transform 0.5s',
                                    }}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        className="gx-rounded-image"
                                        style={{
                                            width: `${imageSize}px`,
                                            borderRadius: '15px',
                                            
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="gx-mobile-image-row">
                            {imageData.slice(6, 9).map((item, index) => {
                                if (item.text) {
                                    // Render the text with the specific class
                                    return (
                                        <div key={index} style={{ width: textBoxSize }} className="gx-center-text-wrapper gx-text-box-4">
                                            <p
                                                className="gx-mb-0"
                                                style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}
                                            >
                                                {item.text}
                                            </p>
                                        </div>
                                    );
                                } else {


                                    return (
                                        <div
                                            key={index}
                                            className={`gx-image-wrapper gx-image-${index}`} // Adjust the index for class
                                            style={{ ...getCurveTransformStyleInMobile(index, false, 2), position: 'relative' }}
                                        >
                                            <img
                                                src={item.src}
                                                alt={item.alt}
                                                className="gx-rounded-image"
                                                style={{
                                                    width: `${imageSize}px`,
                                                    borderRadius: '15px',
                                                   
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            })}

                            
                        </div>

                        <div className="gx-mobile-image-row">
                            {imageData.slice(0, 3).map((image, index) => (
                                <div
                                    key={index}
                                    className={`gx-image-wrapper gx-image-${index + 1}`}
                                    style={{
                                        ...getCurveTransformStyleInMobile(index, true, 3),
                                        transition: 'transform 0.5s',
                                    }}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        className="gx-rounded-image"
                                        style={{
                                            width: `${imageSize}px`,
                                            borderRadius: '15px',
                                           
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    :

                    <div className="gx-images-flex-container">


                        {/* First Row */}
                        <div className="gx-image-row">
                            {imageData.slice(0, 5).map((image, index) => (
                                <div
                                    key={index}
                                    className={`gx-image-wrapper gx-image-${index + 1}`}
                                    style={{
                                        ...getCurveTransformStyle(index),
                                        transition: 'transform 0.5s',
                                    }}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        className="gx-rounded-image"
                                        style={{
                                            width: `${imageSize}px`,
                                            borderRadius: '40px',
                                            
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Second Row */}
                        <div className="gx-image-row gx-mt-5">
                            {imageData.slice(5, 10).map((item, index) => {
                                if (item.text) {
                                    // Render the text with the specific class
                                    return (
                                        <div key={index} style={{ width: textBoxSize }} className="gx-center-text-wrapper gx-text-box-4">
                                            <p
                                                className="gx-mb-0"
                                                style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}
                                            >
                                                {item.text}
                                            </p>
                                        </div>
                                    );
                                } else {


                                    return (
                                        <div
                                            key={index}
                                            className={`gx-image-wrapper gx-image-${index + 6}`} // Adjust the index for class
                                            style={index !== 2 ? { ...getCurveTransformStyle(index), position: 'relative' } : { position: 'relative' }}
                                        >
                                            <img
                                                src={item.src}
                                                alt={item.alt}
                                                className="gx-rounded-image"
                                                style={{
                                                    width: `${imageSize}px`,
                                                    borderRadius: '40px',
                                                    
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
            }
        </section>
    );
};

export default CurveImageSection;

